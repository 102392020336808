import styled from "styled-components";
import { FormFieldWithError } from "../field-error/field-error.styles";

export const VerifiedInputDiv = styled(FormFieldWithError)`
  padding: 10px 0px 0px 0px;
`;

export const StyledVerifiedInput = styled.input`
  height: 40px;
  border: 2px solid
    ${({ theme }) => {
      return theme.colors.periwinkle[100];
    }};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 8px;
  width: 100%;
  &:focus {
    outline: none;
  }
  background: url("/assets/verified.svg") no-repeat
    ${({ theme }) => theme.colors.periwinkle[200]} right;
  background-position-x: 99%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const FlagDiv = styled.div`
  height: 40px;
  background: ${({ theme }) => {
    return theme.colors.periwinkle[200];
  }};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: flex;
`;
