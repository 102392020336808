import React from "react";
import { SelectProps } from "./select.types";
import { StyledSelect, SelectDiv, StyledOption } from "./select.styles";
import { FieldError } from "../field-error/field-error";
import { Label } from "../label/label";
import { useController } from "react-hook-form";
import { constants } from "constants/constants";

const Select = <T extends Record<string, string | boolean>>({
  options,
  name,
  placeholder,
  label,
  onChange,
  control,
  onBlur,
  testId
}: SelectProps<T>): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    control
  });
  const { error } = fieldState;

  return (
    <SelectDiv>
      {label && <Label id={name}>{label}</Label>}

      <StyledSelect
        {...field}
        {...fieldState}
        data-testid={testId}
        // @ts-expect-error
        onChange={(e) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
          field.onBlur();
        }}>
        <StyledOption value="">
          {placeholder || constants.select.default}
        </StyledOption>
        {options.length &&
          options.map((option) => {
            return (
              <StyledOption key={option.value} value={option.value}>
                {option.name}
              </StyledOption>
            );
          })}
      </StyledSelect>

      {error?.message && <FieldError message={error.message} />}
    </SelectDiv>
  );
};

export { Select };
