export enum UserType {
  STUDENT = "student",
  PARENT = "parent"
}

export enum CountryCode {
  Indonesia = "+62"
}

export enum Salutation {
  Ibu = "ibu",
  Bpk = "bpk"
}

export enum Grade {
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  TEN = "10",
  ELEVEN = "11",
  TWELVE = "12"
}

export enum Stream {
  IPA = "IPA",
  IPS = "IPS",
  SMK = "SMK",
  SD = "SD"
}

export enum ClassType {
  SMA = "SMA",
  SMP = "SMP",
  SD = "SD"
}

export enum LoginClassType {
  SD = "SD",
  "SMP/MTs" = "SMP/MTs",
  "SMA/MA" = "SMA/MA",
  "SMK" = "SMK"
}

export enum EventTypes {
  webCheckout = "webCheckout"
}
export interface IComponentWithSource {
  eventSource?: keyof typeof EventTypes;
  height?: string;
  width?: string;
}
