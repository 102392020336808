import React from "react";
import { VideoCardLoader } from "components/video-card/video-card-loader/video-card-loader";
import {
  TanyaAllVideoContainer,
  TanyaAllVideoGrid,
  TanyaAllVideosTitle
} from "styles/pages/tanya-topic.styles";
import { VideoListContainer } from "styles/common/container";
import { BreadCrumbLoader } from "../../components";
import { SearchBarLoader } from "components/search-bar/search-bar-loader/search-bar-loader";

const videoCardCount = 4;
const breadCrumbCount = 3;

const CategoryPageLoader: React.FC = () => (
  <div aria-label={"category-page-loader"}>
    <SearchBarLoader />
    <TanyaAllVideoContainer>
      <VideoListContainer>
        <BreadCrumbLoader count={breadCrumbCount} />
        <TanyaAllVideosTitle>Video Contoh Soal </TanyaAllVideosTitle>
        <TanyaAllVideoGrid>
          <VideoCardLoader id="category-page-loader" count={videoCardCount} />
        </TanyaAllVideoGrid>
      </VideoListContainer>
    </TanyaAllVideoContainer>
  </div>
);

export { CategoryPageLoader };
