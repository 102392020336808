import React from "react";
import type { IIcon } from "./icons.types";

const Edit: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1511 5.80092L16.1935 4.84333C15.6615 4.31134 14.7039 4.31134 14.0655 4.84333L13.2143 5.69452C12.7887 6.12011 12.7887 6.65211 13.2143 7.0777L15.0231 8.88648C15.4487 9.31207 15.9807 9.31207 16.4063 8.88648L17.2575 8.03529C17.82 7.47277 17.8958 6.43931 17.1511 5.80092Z"
        fill={color}
      />
      <path
        d="M12.1503 8.03524C11.7247 7.60964 11.1927 7.60964 10.7671 8.03524L3.95759 14.9511C3.95759 14.9511 3.85119 15.0575 3.85119 15.1639L3 18.5687C3 18.6751 3 18.7815 3.1064 18.8879C3.2128 18.9943 3.3192 18.9943 3.3192 18.9943H3.42559L6.72395 18.2495C6.93675 18.2495 7.04315 18.1431 7.14954 18.0367L13.9591 11.2272C14.3846 10.8016 14.3846 10.2696 13.9591 9.84401L12.1503 8.03524Z"
        fill={color}
      />
      <path
        d="M12.5783 19.5551L19.7003 19.5552C20.4003 19.5552 21.0003 18.9552 21.0003 18.2552C21.0003 17.5552 20.4003 16.9552 19.7003 16.9552L12.5783 16.9551C11.8783 16.9551 11.2783 17.5551 11.2783 18.2551C11.2783 18.9551 11.8783 19.5551 12.5783 19.5551Z"
        fill={color}
      />
    </svg>
  );
};

export { Edit };
