import React from "react";
import Image from "next/legacy/image";

import { useAuth } from "stores/auth/auth-context";

import GetOnAppStore from "public/assets/get_colearn_on_app_store.svg";
import GetOnPlayStore from "public/assets/get_colearn_on_google_play.svg";
import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import { paketPageEvents } from "services/mixpanel/web-checkout-events";
import trackEvent from "services/mixpanel/mixpanel";
import {
  Wrapper,
  DownloadAppContainer,
  DownloadAppImageWrapper
} from "./download-app-ctas.styles";

interface DownloadAppCTAsProps {
  eventSource?: string;
}

export const DownloadAppCTAs: React.FC<DownloadAppCTAsProps> = ({
  eventSource
}) => {
  const { user } = useAuth();

  const handleAndroidDownloadClicked = () => {
    const eventName = paketPageEvents.downloadAndroidAppClicked;
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user
    });
    trackEvent({
      eventName,
      payload: { ...payload, source: eventSource || "" }
    });
  };
  const handleIOSDownloadClicked = () => {
    const eventName = paketPageEvents.downloadIOSAppClicked;
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user
    });
    trackEvent({
      eventName,
      payload: { ...payload, source: eventSource || "NA" }
    });
  };

  return (
    <Wrapper>
      <DownloadAppContainer>
        <DownloadAppImageWrapper
          href="https://colearn.app.link/DownloadCoLearn"
          data-testid="appstore-button"
        >
          <Image
            src={GetOnAppStore}
            alt="Download Colearn from Apple App Store"
            objectFit="contain"
            layout="fill"
            onClick={handleIOSDownloadClicked}
          />
        </DownloadAppImageWrapper>
        <DownloadAppImageWrapper
          href="https://colearn.app.link/DownloadCoLearn"
          data-testid="googleplay-button"
        >
          <Image
            src={GetOnPlayStore}
            alt="Download Colearn from Google Play Store"
            objectFit="contain"
            layout="fill"
            onClick={handleAndroidDownloadClicked}
          />
        </DownloadAppImageWrapper>
      </DownloadAppContainer>
    </Wrapper>
  );
};
