import styled from "styled-components";

const IconButton = styled.button`
  --webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export { IconButton };
