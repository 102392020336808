import styled from "styled-components";
import Image from "next/legacy/image";
import { breakpoints } from "styles";

type IPersonalDataItem = {
  isBold?: boolean;
  noPaddingTop?: boolean;
};

export const ProfileDetailSubHeading = styled.div`
  padding: 12px 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;

  @media ${breakpoints.laptop} {
    padding: 34px 0 8px 0;
  }
`;

export const PersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral["100"]};
  border-radius: 8px;
  padding: 16px;
`;

export const UserAvatar = styled(Image)`
  border-radius: 50%;
`;

export const PersonalDataItem = styled.div<IPersonalDataItem>`
  padding-top: ${(props) => (props.noPaddingTop ? "0" : "8px")};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
`;

export const DashedLine = styled.div`
  margin: 24px 0 12px 0;
  border-bottom: 2px dashed ${({ theme }) => theme.colors.neutral["200"]};
  @media ${breakpoints.laptop} {
    margin-top: 32px;
  }
`;

export const ProfileDetailFooter = styled.div`
  padding-top: 24px;
  color: ${({ theme }) => theme.colors.neutral["700"]};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  @media ${breakpoints.laptop} {
    padding-top: 34px;
  }
`;

export const LogoutButton = styled.div`
  padding: 12px 0;
  font-weight: bold;
  cursor: pointer;
`;
