import {
  ICreateCallMeBackRequestPayload,
  ICreateCallMeBackRequestResponse,
  IUpdateCallMeBackRequestResponse,
  IUpdateCallMeBackRequestPayload
} from "./callMeBackService.types";
import wretch from "wretch";
import { generateUUID } from "utils/utils";

const BASE_URL = process.env.NEXT_PUBLIC_BE_URL;

export const createCallMeBackRequest = (
  payload: ICreateCallMeBackRequestPayload,
  access_token: string | null
): Promise<ICreateCallMeBackRequestResponse> => {
  return access_token
    ? wretch(`${BASE_URL}/api/v1/callmeback`)
        .headers({
          Authorization: `Bearer ${access_token}`,
          "x-iq-request-id": generateUUID()
        })
        .post(payload)
        .json()
    : wretch(`${BASE_URL}/api/v1/callmeback`)
        .headers({
          "x-iq-request-id": generateUUID()
        })
        .post(payload)
        .json();
};

export const updateCallMeBackRequest = ({
  payload,
  updateId
}: {
  payload: IUpdateCallMeBackRequestPayload;
  updateId: string;
}): Promise<IUpdateCallMeBackRequestResponse> => {
  return wretch(`${BASE_URL}/api/v1/callmeback/${updateId}`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .put(payload)
    .json();
};
