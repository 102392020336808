import React, { useContext, createContext } from "react";
import { IKelasLiveContext } from "./kelas-live-context.types";
import { useSubscriptionStatus } from "hooks/use-subscription-status/use-subscription-status";
import { useAuth } from "stores/auth/auth-context";

const KelasLiveContext = createContext<IKelasLiveContext>(
  {} as IKelasLiveContext
);

const useKelasLiveContext = (): IKelasLiveContext =>
  useContext(KelasLiveContext);

const KelasLiveContextProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const subscriptionStatusValue = useSubscriptionStatus();
  const { user } = useAuth();

  return (
    <KelasLiveContext.Provider
      value={{ userSubscription: subscriptionStatusValue, user }}>
      {children}
    </KelasLiveContext.Provider>
  );
};
export { KelasLiveContext, useKelasLiveContext, KelasLiveContextProvider };
