import styled from "styled-components";
import { FieldError } from "react-hook-form";
import { FormFieldWithError } from "../field-error/field-error.styles";
import { SelectProps } from "../search-select/search-select.types";

export const StyledSelect = styled.select<
  SelectProps<Record<string, string | boolean>> & {
    error?: FieldError;
    onChange: () => void;
  }
>`
  width: 100%;
  height: 40px;
  border: 2px solid
    ${({ theme, error }) => {
      return error?.message
        ? theme.colors.red[100]
        : theme.colors.periwinkle[500];
    }};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 8px;
  position: relative;
  appearance: none;
  background: url("../../../../assets/select-arrow.svg") no-repeat right;
  background-position-x: 95%;
  &:focus {
    outline: none;
  }
`;

export const SelectDiv = styled(FormFieldWithError)`
  padding: 8px 0px;
`;

export const StyledOption = styled.option`
  padding: 8px;
`;
