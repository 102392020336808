import styled from "styled-components";
import { breakpoints } from "styles";

export const TanyaAllVideoContainer = styled.main`
  margin: 30px 0;
`;

export const TanyaAllVideoGrid = styled.div`
  margin: 32px 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  @media ${breakpoints.tablet} {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`;
export const TanyaAllVideosTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  margin: 16px 0 0 0;
  min-height: 30px;
`;
