import React from "react";
import { StyledButton, LoadingWrapper } from "./button.styles";
import { ButtonProps, ButtonSize } from "./button.types";
import { LoadingSpinner } from "../../icons/loading-spinner";

const Button = ({
  children,
  variant,
  onClick,
  disabled,
  isFullWidth,
  isLoading = false,
  loadingText,
  size = ButtonSize.md,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <StyledButton
      onClick={onClick}
      $variant={variant}
      disabled={isLoading ? isLoading : disabled}
      $isLoading={isLoading}
      $isFullWidth={isFullWidth}
      $size={size}
      {...props}
    >
      {isLoading ? (
        <LoadingWrapper>
          <LoadingSpinner /> {loadingText}
        </LoadingWrapper>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export { Button };
