import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const StyledFooter = styled.footer`
  padding: 30px;
  background: ${({ theme }) => theme.colors.white};
  @media ${breakpoints.mobile} {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  }
`;

export const FooterContainer = styled(Container)`
  padding: 0 16px;
  overflow: hidden;
  @media ${breakpoints.tablet} {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export const FooterDetailsContainer = styled.div`
  display: grid;
  position: relative;
  gap: 20px;
  margin-top: 40px;
  grid-template-areas:
    "features features features features"
    "colearn colearn contactUs contactUs"
    "address address address address";
  @media ${breakpoints.tablet} {
    margin-top: 5px;
    grid-template-areas:
      "features colearn"
      "address contactUs";
  }
`;
export const FooterMessage = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 1.25;
  font-weight: bold;
  margin: 20px 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 1.5;
    max-width: 420px;
  }
`;

export const FooterHeading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: bold;
  margin: 16px 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }
`;

export const DownloadContainer = styled.div`
  margin-top: 24px;
  @media ${breakpoints.tablet} {
    margin-top: 87px;
  }
`;

export const FooterMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FooterMenuLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  border-bottom: 1px dotted transparent;

  &:hover,
  &:focus,
  &:active {
    border-color: currentColor;
  }
`;

export const FooterMenuLinkHighlight = styled.a`
  color: ${({ theme }) => theme.colors.brand[500]} !important;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

export const FooterMenuItem = styled.li`
  margin-bottom: 18px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.neutral[800]};

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

export const FeaturesNav = styled.nav`
  grid-area: features;
`;

export const CocoImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
  height: 105px;
  transition: 0.5s ease;

  @media ${breakpoints.tablet} {
    width: 200px;
    height: 140px;
    right: unset;
    top: unset;
    bottom: 10%;
    left: -50%;
    &:hover {
      transform: scale(0.8);
    }
  }
`;

export const AddressContainer = styled.div`
  grid-area: address;
`;

export const ColearnContainer = styled.div`
  grid-area: colearn;
`;

export const ContactUsContainer = styled.div`
  grid-area: contactUs;
`;

export const SubFooter = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  margin-top: 5px;
  @media ${breakpoints.tablet} {
    margin-top: 110px;
  }
`;

export const SubFooterContainer = styled(Container)`
  position: relative;
  padding: 24px 30px;
  @media ${breakpoints.tablet} {
    display: flex;
    align-items: center;
  }
`;

export const FooterCopyright = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.neutral[900]};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const FooterSocialIcon = styled.a`
  position: relative;
  text-decoration: none;
  display: block;
  width: 24px;
  height: 24px;
`;

export const FooterSocialWrapper = styled.nav`
  @media ${breakpoints.tablet} {
    margin-left: auto;
  }
`;
export const FooterSocialContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const FooterExtraLinksContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0;
  display: flex;
  align-items: center;
  @media ${breakpoints.tablet} {
    margin: 0 32px;
  }
`;

export const FooterExtraLinkItem = styled.li`
  margin-right: 18px;
  border-bottom: 1px dotted transparent;
  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &:active,
  &:focus {
    border-color: currentColor;
  }

  @media ${breakpoints.tablet} {
    margin-right: 24px;
  }
`;

export const FooterExtraLink = styled.a`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: inherit;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const FooterSocialIconItem = styled.li`
  margin-right: 16px;
  transition: 0.3s ease-in-out;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const NecoImage = styled.div`
  position: absolute;
  top: calc(-1 * calc(100% - 50px));
  right: 16px;
  width: 75px;
  height: 140px;

  @media ${breakpoints.tablet} {
    top: -110%;
    right: 0;
  }

  @media ${breakpoints.mobile} {
    top: -60%;
    right: 0;
  }
`;

export const FooterSection = styled.section``;

export const FooterAddressText = styled.li`
  color: ${({ theme }) => theme.colors.neutral[800]};
  line-height: 20px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  @media ${breakpoints.tablet} {
    line-height: 26px;
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

export const ContactUsMenuItem = styled(FooterMenuItem)`
  margin: 8px 0;
  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.tablet} {
    margin: 12px 0;
  }
`;
