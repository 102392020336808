import React, { useEffect } from "react";
import { ToastPropsType } from "./toast.types";
import { Error } from "components/icons/toast-icons/error";
import {
  ToastWrapper,
  ToastInfo,
  ToastCloseIconWrapper,
  ToastHeading,
  ToastHeadingAndBodyContainer,
  ToastIconContainer
} from "./toast.styles";
import { CloseIcon } from "components/icons/toast-icons/close";
import { Success } from "components/icons/toast-icons/success";
import { Loading } from "components/icons/toast-icons/loading";

const ToastIcons = {
  error: Error,
  success: Success,
  loading: Loading
  // other variant will go here as design catches up
};

const DEFAULT_DURATION = 5000;

const Toast = ({
  heading,
  variant,
  onCloseToast,
  duration = DEFAULT_DURATION,
  body,
  position,
  width
}: ToastPropsType): JSX.Element => {
  const ToastIcon = ToastIcons[variant];

  useEffect(() => {
    setTimeout(() => {
      if (onCloseToast) {
        onCloseToast();
      }
    }, duration);
  }, []);

  return (
    <ToastWrapper width={width} position={position} variant={variant}>
      <ToastInfo>
        <ToastIconContainer>
          <ToastIcon />
        </ToastIconContainer>
        {body ? (
          <ToastHeadingAndBodyContainer>
            <ToastHeading>{heading}</ToastHeading>
            {body}
          </ToastHeadingAndBodyContainer>
        ) : (
          heading
        )}
      </ToastInfo>

      <ToastCloseIconWrapper variant={variant} onClick={onCloseToast}>
        <CloseIcon />
      </ToastCloseIconWrapper>
    </ToastWrapper>
  );
};

export { Toast };
