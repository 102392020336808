import React from "react";
import { IIcon } from "../icons.types";

const Error: React.FC<IIcon> = ({
  height = 20,
  width = 20,
  color = "currentColor"
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM11.4 15.3C11.4 16.1 10.8 16.7 10 16.7C9.2 16.7 8.6 16.1 8.6 15.3V10C8.6 9.2 9.2 8.6 10 8.6C10.8 8.6 11.4 9.2 11.4 10V15.3ZM10 6.6C9 6.6 8.3 5.8 8.3 4.9C8.3 3.9 9.1 3.2 10 3.2C10.9 3.2 11.7 4 11.7 4.9C11.7 5.8 11 6.6 10 6.6Z"
        fill={color}
      />
    </svg>
  );
};

export { Error };
