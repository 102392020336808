export const devices = {
  tablet: "768px",
  laptop: "1200px"
};
const breakpoints = {
  mobile: `(max-width: ${devices.tablet})`,
  tablet: `(min-width: ${devices.tablet})`,
  laptop: `(min-width: ${devices.laptop})`
};

export { breakpoints };
