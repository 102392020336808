import { colors } from "./constants/colors";
import { fonts, fontSizes } from "./constants/typography";
import type { IColors } from "./constants/colors";
import type { IFonts, IFontSizes } from "./constants/typography";

export type ITheme = {
  colors: IColors;
  fonts: IFonts;
  fontSizes: IFontSizes;
};

export type IThemeWrapper = {
  theme: ITheme;
};

const theme: ITheme = {
  colors,
  fonts,
  fontSizes
};

export { theme };
