import { useBoolean } from "hooks/use-boolean/use-boolean";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getLoadingPageName } from "utils/utils";
import { ILoadingPageName, IUseLoading } from "./use-loading.types";

const useLoading = (): IUseLoading => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useBoolean(false);
  const [loadingPageName, setLoadingPageName] =
    useState<ILoadingPageName>(null);

  const handleRouteChangeStart = (url: string) => {
    const pageUrl = getLoadingPageName(url);
    if (pageUrl) {
      setIsLoading.on();
      setLoadingPageName(pageUrl);
      window.scrollTo(0, 0);
    }
  };
  const handleRouteChangeComplete = () => {
    setIsLoading.off();
    setLoadingPageName(null);
  };

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  return {
    isLoading,
    loadingPageName
  };
};

export { useLoading };
