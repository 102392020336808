export const webCheckoutEventNames = {
  tenureClicked: "Tenure Clicked on Select Tenure WC",
  continueClickedOnTenureScreen: "Continue Clicked on Select Tenure WC",
  valProp1Clicked: "Value prop 1 on Select Tenure WC",
  valProp2Clicked: "Value prop 2 on Select Tenure WC",
  valProp3Clicked: "Value prop 3 on Select Tenure WC",
  changeTenureClicked: "Change Tenure Clicked WC",
  haveCouponCodeClicked: "Have Coupon Code Clicked WC",
  applyCouponCodeClicked: "Apply Coupon Code Clicked WC",
  couponCodeSuccess: "Coupon Code Success WC",
  invalidCouponCode: "Invalid Coupon Code WC",
  tenureClickedOnTenurePickerPopup: "Tenure Clicked on Tenure Popup WC",
  onCloseTenureChangeModal: "Close clicked on Tenure Popup WC",
  continueClickedOnTenureSelectPopup: "Continue Clicked on Tenure Popup WC",
  editMobileOnVerifyOTP: "Edit Mobile on Verify OTP WC",
  resendOTPClicked: "Resend OTP Clicked on Verify OTP WC",
  invalidOTPEntered: "Invalid OTP Submitted ",
  userSelectedOnUserType: "User Selected on User Type WC",
  continueClickedOnCreateProfile: "Continue Clicked on Create Profile WC",
  editStudentInfo: "Edit Student Info on Summary Page WC",
  editParentInfo: "Edit Parent Info on Summary Page WC",
  continuePaymentOnSummary: "Continue Payment on Summary Page WC",
  shareLinkOnSummary: "Share Link on Summary Page WC",
  continueClickedOnEditStudent:
    "Continue Clicked on Edit Student Info Popup WC",
  closeClickedOnEditStudent: "Close Clicked on Edit Student Info Popup WC",
  continueClickedOnEditParent: "Continue Clicked on Edit Parent Info Popup WC",
  closeClickedOnEditParent: "Close Clicked on Edit Parent Info Popup WC",
  learnMoreClickedOnSummary: "Learn More Clicked on Payment Summary WC",
  paymentSuccesful: "Payment Successful WC",
  paymentFailed: "Payment Failed WC ",
  retryClickedAfterPaymentFailed: "Retry Clicked Payment Failed Popup WC",
  closeClickedOnPaymentFailedPopup: "Close Clicked Payment Failed Popup WC",
  chatClickedPaymentPopup: "Chat Clicked Payment Failed Popup WC",
  childCardClicked: "Child Card Clicked on Select Child WC",
  continueClickedOnSelectChild: "Continue Clicked on Select Child WC"
};

export const paketPageEvents = {
  topBannerHozScrolled: "Top Banner Hoz Scrolled WC",
  selectGradeDropdownClicked: "Select Grade Dropdown Clicked WC",
  gradeSelected: "Grade Selected from Dropdown WC",
  explorePackageClicked: "Explore Package Clicked WC",
  packageSelectedContinue: "Package Selected Continue Clicked WC",
  viewMathsScheduleClicked: "View Maths Schedule CTA Clicked WC",
  viewPhysicsScheduleClicked: "View Physics Schedule CTA Clicked WC",
  viewChemistryScheduleClicked: "View Chem Schedule CTA Clicked WC",
  getBNPLClicked: "Get BNPL Info CTA Clicked WC",
  val1PropClicked: "Val Prop 1 Clicked WC",
  val2PropClicked: "Val Prop 2 Clicked  WC",
  val3PropClicked: "Val Prop 3 Clicked WC",
  watchClassOnValPropClicked: "Watch Class on Val Prop Clicked WC",
  testimonialRightHozScrollClicked: "Testimon Right Hoz Scroll Clicked WC",
  testimonialLeftHozScrollClicked: "Testimon Left Hoz Scroll Clicked WC",
  fAQExpandButtonClicked: "FAQ Expand Button Clicked WC",
  downloadAndroidAppClicked: "Download Android App CTA Clicked",
  downloadIOSAppClicked: "Download Ios App CTA Clicked",
  changePackageClicked: "Change Package Clicked on Package Panel WC",
  hozScrollPageViewed: "Hoz scroll page viewed",
  submitClickedOnBNPLForm: "Submit Clicked on BNPL Form WC",
  okClickedOnBNPLForm: "OK Clicked on BNPL Success Popup WC",
  BNPLFormClosed: "Close Clicked on BNPL Form WC",
  contactColearnClicked: "Contact CoLearn Clicked"
};
