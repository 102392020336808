import styled from "styled-components";
import { FormFieldWithError } from "../field-error/field-error.styles";
export const EditableInputDiv = styled(FormFieldWithError)`
  position: relative;
  margin-bottom: 12px;
`;

export const StyledEditableInput = styled.input`
  height: 40px;
  border: 2px solid
    ${({ theme }) => {
      return theme.colors.periwinkle[100];
    }};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 8px;
  width: 100%;

  :disabled {
    background: ${({ theme }) => theme.colors.periwinkle[200]};
  }
`;
export const InputWithActionWrapper = styled.div`
  position: relative;
  width: 100%;
`;
export const EditButton = styled.button`
  display: grid;
  background: transparent;
  place-items: center;
  position: absolute;
  border: none;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
`;
