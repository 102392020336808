import React from "react";
import { StyledTextArea, TextAreaDiv, WordCount } from "./text-area.styles";
import { useController } from "react-hook-form";
import { FieldError } from "../field-error/field-error";
import { Label } from "../label/label";
import { TextAreaProps } from "./text-area.types";

const TextArea = <T extends Record<string, string | boolean | string[]>>({
  control,
  placeholder,
  label,
  name,
  showWordCount,
  maxLength,
  minLength,
  testId
}: TextAreaProps<T>): JSX.Element => {
  const {
    field,
    fieldState,
    fieldState: { error }
  } = useController({
    control,
    name
  });

  return (
    <TextAreaDiv>
      {label && <Label id={field.name}>{label}</Label>}
      {/* @ts-expect-error */}
      <StyledTextArea
        data-testid={testId}
        placeholder={placeholder}
        {...field}
        {...fieldState}
        maxLength={maxLength}
        minLength={minLength}
      />
      {error?.message && (
        <div>
          <FieldError message={error.message} />
        </div>
      )}
      {showWordCount && (
        <WordCount>
          {field.value ? `${field.value}`.length : 0} / {maxLength}
        </WordCount>
      )}
    </TextAreaDiv>
  );
};

export { TextArea };
