import React, { useEffect } from "react";
import * as yup from "yup";
import { Button } from "components/atoms/button/button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import trackEvent from "services/mixpanel/mixpanel";
import { bannerIntentEventNames } from "services/mixpanel/tanya-events";

import { ContactMeJoiningReasonFormProps } from "../use-contact-me.types";

import {
  StyledForm,
  SubmitButton,
  TextAreaWrapper
} from "./contact-me-form.styles";
import { TextArea } from "components/form-component/text-area/text-area";
import { ButtonContainer } from "./contact-me-form.styles";

const FormSchema = yup.object().shape({
  reason: yup.string().required("Isi kolom ini dulu ya, CoFriends")
});

export const ContactMeJoiningReasonForm: React.FC<
  ContactMeJoiningReasonFormProps
> = ({ onSkip, onSubmit, leadSource, metaData }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<{ reason: string }>({
    defaultValues: {
      reason: ""
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(FormSchema)
  });

  useEffect(() => {
    trackEvent({
      eventName: bannerIntentEventNames.leadReasonPopupViewed,
      payload: { leadSource, ...metaData }
    });
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextAreaWrapper>
        <TextArea
          testId="kendala-belajar-textfield"
          name="reason"
          maxLength={255}
          control={control}
          placeholder="Tulisin kendala belajar yang kamu hadapi biar CoLearn bisa bantu"
          showWordCount
        />
      </TextAreaWrapper>
      <ButtonContainer>
        <SubmitButton
          data-testId="sip-kirim-button"
          type="submit"
          variant="primary"
          disabled={!isValid}
        >
          Sip, kirim
        </SubmitButton>
        <Button
          data-testid="lewati-tanpa-jawab-button"
          type="button"
          variant="link"
          onClick={onSkip}
        >
          Lewati tanpa jawab
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
};
