import { ClassType } from "types/common/common.types";

const constants = {
  mediaBaseUrl: `https://media.sessions.colearn.id`,
  WEB_CHECKOUT_PATHS: [
    "/paket/tenure-pick",
    "/user/select-role",
    "/user/add-user",
    "/user/update-user",
    "/user/select-student",
    "/paket/user/add-user",
    "/paket/user/summary",
    "/login",
    "/auth-redirect",
    "/payment/success",
    "/payment/failed",
    "/paket/user/add-parent",
    "/paket/user/select-student",
    "/paket/user/choose-payment-method",
    "/bimbel/user/qna/[classId]"
  ],
  redirect_to: {
    kelas_live: "/bimbel",
    kelas_live_dashboard: "/bimbel/user",
    paket: "/paket",
    login: "/login",
    choosePaymentMethod: "/paket/user/choose-payment-method"
  },
  TANYA_ALL_QUESTION_PAGES: "/tanya/questions/page/1",
  OTP_RESEND_TIME: 45,
  CHECK_PAYMENT_POLLING_RATE: 30 * 1000,
  CHECK_NPS_POLLING_RATE: 30 * 1000,
  CHECK_NPS_POLLING_DELAY:
    process.env.NEXT_ENV !== "production" ? 2 * 60 * 1000 : 30 * 60 * 1000,
  select: {
    default: "Select your option"
  },
  source: {
    tanya: "tanya",
    webCheckout: "web-checkout"
  },
  key: {
    BACKSPACE: "Backspace"
  },
  SOMETHING_WENT_WRONG: "Ada yang salah",
  EDIT_USER_SUCCESS_MESSAGE: "Sip, datamu berhasil diperbarui.",
  NO_PACKAGES_AVAILABLE: "Tidak ada paket yang tersedia",
  userContext: {
    sessionKey: "USER_DATA",
    cookieKey: "NEXT_AUTH_COOKIE",
    cookieKeyStudent: "NEXT_AUTH_COOKIE_STUDENT",
    cookieKeyParent: "NEXT_AUTH_COOKIE_PARENT",
    cookiePrimaryUserSubscriptionType:
      "NEXT_AUTH_COOKIE_PRIMARY_USER_SUBSCRIPTION_TYPE",
    cookieTanyaSubscriptionStatus: "TANYA_SUBSCRIPTION_STATUS"
  },
  DELAY_ERROR: 800,
  VIDEO_PLAYER_IDLE_TIMEOUT: 2000,
  ONE_TIME_FIXED: "one_time_fixed",
  FLOATING_BUTTON_SCROLL_TOP: 200,
  REDIRECT_TO_AFTER_LOGIN: "redirectToAfterLogin",
  LOGGED_IN_FROM_PAGE: "loggedInFrom",
  KELAS_LIVE_TRIAL_ACTIVATION: "kelas_live_trial_activation",
  CALL_ME_BACK_AUTO_OPEN_INTERVAL: 10 * 1000,
  CONTACT_ME_AUTO_OPEN_INTERVAL: 15 * 1000,
  DEFAULT_UNEXPECTED_ERROR_MESSAGE: "Ada yang salah",
  VERIFIED_VIDEO_TOOLTIP_VIEWED_KEY: "verified_video_tooltip_viewed",
  TANYA_VERIFIED_VIDEO_CARD_TRANSITION_BASE_DELAY: 1.5 * 1000,
  USER_SPEND_TIME_KEY: "user_spend_time_data",
  USER_SPEND_TIME_LIMIT: 900, // in seconds
  ANON_USER_REGISTER_VIA_BLOCKER: "anonUserRegisterViaBlocker",
  BIMBEL_WELCOME_MODAL_CLICKED: "BIMBEL_WELCOME_MODAL_CLICKED",
  BIMBEL_WELCOME_MODAL_THANKYOU_CLICKED:
    "BIMBEL_WELCOME_MODAL_THANKYOU_CLICKED",
  MWEB_ACCESS_BLOCKER_TO_APP_DATA_KEY: "tanya_mweb_access_data_to_app",
  LEAD_GENERATION_VARIATION_KEY: "lead_generation_variations",
  CLASS_SCHEDULE_TOOLTIP_VIEWED_KEY: "class_schedule_tooltip_viewed"
};

export interface IClassOptions {
  name: string;
  value: string;
}

interface INameTitle {
  name: string;
  value: string;
}

interface ILoginClassTypeOptions {
  name: string;
  value: string;
}
interface IClassGradeKey {
  grade: string;
  class: string;
  stream: string | null;
}
export const classOptions: IClassOptions[] = [
  // value is grade_stream_classtype, for 7,8,9, stream is null as per backend changes
  {
    name: "4 SD",
    value: "4_SD_SD"
  },
  {
    name: "5 SD",
    value: "5_SD_SD"
  },
  {
    name: "6 SD",
    value: "6_SD_SD"
  },
  {
    name: "7 SMP",
    value: "7_null_SMP/MTs"
  },
  {
    name: "8 SMP",
    value: "8_null_SMP/MTs"
  },
  {
    name: "9 SMP",
    value: "9_null_SMP/MTs"
  },
  {
    name: "10 IPA",
    value: "10_IPA_SMA/MA"
  },
  {
    name: "11 IPA",
    value: "11_IPA_SMA/MA"
  },
  {
    name: "12 IPA",
    value: "12_IPA_SMA/MA"
  },
  {
    name: "10 IPS",
    value: "10_IPS_SMA/MA"
  },
  {
    name: "11 IPS",
    value: "11_IPS_SMA/MA"
  },
  {
    name: "12 IPS",
    value: "12_IPS_SMA/MA"
  },
  {
    name: "10 SMK",
    value: "10_SMK_SMK"
  },
  {
    name: "11 SMK",
    value: "11_SMK_SMK"
  },
  {
    name: "12 SMK",
    value: "12_SMK_SMK"
  }
];

export const DAY_WEIGHT: { [key: string]: number } = {
  Sen: 1,
  Sel: 2,
  Rab: 3,
  Kam: 4,
  Jum: 5,
  Sab: 6,
  Min: 7
};

export const monetisationClassOptions: IClassOptions[] = classOptions.filter(
  (option) => option.value !== "4_SD_SD"
);

export const classGradeKey: Record<string, IClassGradeKey> = {
  "4_SD_SD": {
    grade: "4",
    class: "SD",
    stream: "SD"
  },
  "5_SD_SD": {
    grade: "5",
    class: "SD",
    stream: "SD"
  },
  "6_SD_SD": {
    grade: "6",
    class: "SD",
    stream: "SD"
  },
  "7_null_SMP/MTs": {
    grade: "7",
    class: "SMP/MTs",
    stream: null
  },
  "8_null_SMP/MTs": {
    grade: "8",
    class: "SMP/MTs",
    stream: null
  },
  "9_null_SMP/MTs": {
    grade: "9",
    class: "SMP/MTs",
    stream: null
  },
  "10_IPA_SMA/MA": {
    grade: "10",
    class: "SMA/MA",
    stream: "IPA"
  },
  "11_IPA_SMA/MA": {
    grade: "11",
    class: "SMA/MA",
    stream: "IPA"
  },
  "12_IPA_SMA/MA": {
    grade: "12",
    class: "SMA/MA",
    stream: "IPA"
  },
  "10_IPS_SMA/MA": {
    grade: "10",
    class: "SMA/MA",
    stream: "IPS"
  },
  "11_IPS_SMA/MA": {
    grade: "11",
    class: "SMA/MA",
    stream: "IPS"
  },
  "12_IPS_SMA/MA": {
    grade: "12",
    class: "SMA/MA",
    stream: "IPS"
  },
  "10_SMK_SMK": {
    grade: "10",
    class: "SMK",
    stream: "SMK"
  },
  "11_SMK_SMK": {
    grade: "11",
    class: "SMK",
    stream: "SMK"
  },
  "12_SMK_SMK": {
    grade: "12",
    class: "SMK",
    stream: "SMK"
  }
};

const { ["4_SD_SD"]: IClassGradeKey, ...monetisationClassGradeKey } =
  classGradeKey;

export { monetisationClassGradeKey };

export const loginClassTypeOptions: ILoginClassTypeOptions[] = [
  {
    name: "SD",
    value: "SD"
  },
  {
    name: "SMP/MTs",
    value: "SMP/MTs"
  },
  {
    name: "SMA/MA",
    value: "SMA/MA"
  },
  {
    name: "SMK",
    value: "SMK"
  }
];

export const nameTitles: INameTitle[] = [
  {
    name: "Bpk",
    value: "bpk"
  },
  {
    name: "Ibu",
    value: "ibu"
  }
];

export const userTypes = [
  {
    name: "Murid",
    value: "student"
  },
  {
    name: "Orangtua",
    value: "parent"
  }
];

export const mapGradeToStream: Record<number, string | null> = {
  5: "SD",
  6: "SD",
  7: null,
  8: null,
  9: null,
  10: "IPA",
  11: "IPA",
  12: "IPA"
};
export const defaultRatingScale = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10"
];

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export enum tanyaListCategory {
  topic = "topic",
  chapter = "chapter",
  section = "section"
}

export const ISubjectEnglish: { [key: string]: string } = {
  Matematika: "Maths",
  Fisika: "Physics",
  Kimia: "Chemistry"
};

export const defaultCurriculum = "2013 Indonesian Curriculum (Revised Version)";

export const progressMessageThreshold = 15;
export { constants };

export const ClassOptionsByClassType = {
  [ClassType.SMA]: [
    {
      name: "Kelas 12",
      value: "12_SMA/MA",
      classType: ClassType.SMA,
      grade: "12"
    },
    {
      name: "Kelas 11",
      value: "11_SMA/MA",
      classType: ClassType.SMA,
      grade: "11"
    },
    {
      name: "Kelas 10",
      value: "10_SMA/MA",
      classType: ClassType.SMA,
      grade: "10"
    }
  ],
  [ClassType.SMP]: [
    {
      name: "Kelas 9",
      value: "9_SMP/MTs",
      classType: ClassType.SMP,
      grade: "9"
    },
    {
      name: "Kelas 8",
      value: "8_SMP/MTs",
      classType: ClassType.SMP,
      grade: "8"
    },
    {
      name: "Kelas 7",
      value: "7_SMP/MTs",
      classType: ClassType.SMP,
      grade: "7"
    }
  ],
  [ClassType.SD]: [
    {
      name: "Kelas 6",
      value: "6_SD",
      classType: ClassType.SD,
      grade: "6"
    },
    {
      name: "Kelas 5",
      value: "5_SD",
      classType: ClassType.SD,
      grade: "5"
    },
    {
      name: "Kelas 4",
      value: "4_SD",
      classType: ClassType.SD,
      grade: "4"
    }
  ]
};

export enum Kurikulums {
  "Kurikulum Merdeka" = "Kurikulum Merdeka",
  "Kurikulum 2013" = "Kurikulum 2013"
}

export const nextYear = (new Date().getFullYear() + 1).toString();
export const currentYear = new Date().getFullYear().toString();
export const previousYear = (parseInt(currentYear) - 1).toString();

export const SyllabusBaseUrl = `${process.env.NEXT_PUBLIC_MEDIA_SESSIONS_URL}/assets/other/syllabus`;

export const SlotSchedulesBaseUrl = `${process.env.NEXT_PUBLIC_MEDIA_SESSIONS_URL}/assets/other/schedule`;
