import type { IIcon } from "./icons.types";

const Hamburger: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = "currentColor"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5001 7.84994H4.50006C4.13208 7.80953 3.7926 7.64196 3.54614 7.37924C3.29974 7.11652 3.16357 6.77687 3.16357 6.42497C3.16357 6.07308 3.29974 5.73342 3.54614 5.4707C3.7926 5.20798 4.13208 5.04041 4.50006 5H19.5001C19.868 5.04041 20.2076 5.20798 20.454 5.4707C20.7004 5.73342 20.8366 6.07308 20.8366 6.42497C20.8366 6.77687 20.7004 7.11652 20.454 7.37924C20.2076 7.64196 19.868 7.80953 19.5001 7.84994Z"
      fill={color}
    />
    <path
      d="M19.5 13.9202H4.5C4.10217 13.9202 3.72064 13.7701 3.43933 13.5029C3.15802 13.2356 3 12.8732 3 12.4952C3 12.1173 3.15802 11.7549 3.43933 11.4876C3.72064 11.2204 4.10217 11.0703 4.5 11.0703H19.5C19.8978 11.0703 20.2794 11.2204 20.5607 11.4876C20.842 11.7549 21 12.1173 21 12.4952C21 12.8732 20.842 13.2356 20.5607 13.5029C20.2794 13.7701 19.8978 13.9202 19.5 13.9202Z"
      fill={color}
    />
    <path
      d="M4.50006 20H19.5001C19.868 19.9596 20.2076 19.792 20.454 19.5293C20.7004 19.2666 20.8366 18.9269 20.8366 18.575C20.8366 18.2231 20.7004 17.8835 20.454 17.6208C20.2076 17.358 19.868 17.1905 19.5001 17.1501H4.50006C4.13208 17.1905 3.7926 17.358 3.54614 17.6208C3.29974 17.8835 3.16357 18.2231 3.16357 18.575C3.16357 18.9269 3.29974 19.2666 3.54614 19.5293C3.7926 19.792 4.13208 19.9596 4.50006 20Z"
      fill={color}
    />
  </svg>
);

export { Hamburger };
