import styled from "styled-components";
import { ToastPropsType, VariantType } from "./toast.types";
import { theme, zIndex, breakpoints } from "styles";

const colorMap: Record<string, string> = {
  error: theme.colors.pink[600],
  success: theme.colors.green[700],
  loading: theme.colors.green[700]
};

const backgroundColorMap: Record<string, string> = {
  error: theme.colors.pink[100],
  success: theme.colors.green[100],
  loading: theme.colors.green[100]
};

export const ToastWrapper = styled.div<{
  variant: VariantType;
  position: ToastPropsType["position"];
  width: ToastPropsType["width"];
}>`
  display: flex;
  position: fixed;
  top: ${({ position }) => position?.top || "40px"};
  left: ${({ position }) => position?.left || "50%"};
  ${({ position }) => (position?.bottom ? `bottom: ${position.bottom}` : "")};
  ${({ position }) => (position?.right ? `right: ${position.right}` : "")};
  transform: translate(-50%, 0);
  text-align: left;
  width: ${({ width }) => width || "80vw"};
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${({ variant }) => backgroundColorMap[variant]};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ variant }) => colorMap[variant]};
  z-index: ${zIndex.toast};
  max-width: 85vw;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    width: ${({ width }) => width || "50vw"};
  }
`;

export const ToastInfo = styled.div`
  display: flex;
  gap: 14px;
`;
export const ToastHeading = styled.h4`
  font-weight: bold;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin-bottom: 5px;
`;
export const ToastHeadingAndBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const ToastCloseIconWrapper = styled.div<{ variant: VariantType }>`
  cursor: pointer;
  color: ${({ variant }) => colorMap[variant]};
`;
export const ToastIconContainer = styled.div`
  min-height: 20px;
  min-width: 20px;
  display: flex;
`;
