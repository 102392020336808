import { Spinner } from "./icons.styles";
import { IIcon } from "./icons.types";

const LoadingSpinner = ({
  height = "22px",
  width = "22px",
  color = "currentColor"
}: IIcon): JSX.Element => {
  return (
    <Spinner width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C11.8284 0 12.5 0.671573 12.5 1.5V4.5C12.5 5.32843 11.8284 6 11 6C10.1716 6 9.5 5.32843 9.5 4.5V1.5C9.5 0.671573 10.1716 0 11 0ZM11 16C11.8284 16 12.5 16.6716 12.5 17.5V20.5C12.5 21.3284 11.8284 22 11 22C10.1716 22 9.5 21.3284 9.5 20.5V17.5C9.5 16.6716 10.1716 16 11 16ZM20.5 12.5C21.3284 12.5 22 11.8284 22 11C22 10.1716 21.3284 9.5 20.5 9.5H17.5C16.6716 9.5 16 10.1716 16 11C16 11.8284 16.6716 12.5 17.5 12.5H20.5ZM6 11C6 11.8284 5.32843 12.5 4.5 12.5H1.5C0.671573 12.5 0 11.8284 0 11C0 10.1716 0.671573 9.5 1.5 9.5H4.5C5.32843 9.5 6 10.1716 6 11ZM16.6549 18.7814C17.2407 19.3671 18.1905 19.3671 18.7763 18.7814C19.362 18.1956 19.362 17.2458 18.7763 16.66L16.6549 14.5387C16.0691 13.9529 15.1194 13.9529 14.5336 14.5387C13.9478 15.1245 13.9478 16.0743 14.5336 16.66L16.6549 18.7814ZM7.46522 7.46154C6.87943 8.04733 5.92968 8.04733 5.3439 7.46154L3.22258 5.34022C2.63679 4.75443 2.63679 3.80468 3.22258 3.2189C3.80836 2.63311 4.75811 2.63311 5.3439 3.2189L7.46522 5.34022C8.05101 5.926 8.05101 6.87575 7.46522 7.46154ZM18.7858 5.34067C19.3715 4.75489 19.3715 3.80514 18.7858 3.21935C18.2 2.63357 17.2502 2.63357 16.6644 3.21935L14.5431 5.34067C13.9573 5.92646 13.9573 6.87621 14.5431 7.46199C15.1289 8.04778 16.0787 8.04778 16.6644 7.46199L18.7858 5.34067ZM7.4569 14.5392C8.04269 15.125 8.04269 16.0747 7.4569 16.6605L5.33558 18.7818C4.74979 19.3676 3.80005 19.3676 3.21426 18.7818C2.62847 18.196 2.62847 17.2463 3.21426 16.6605L5.33558 14.5392C5.92137 13.9534 6.87111 13.9534 7.4569 14.5392Z"
          fill={color}
        />
      </svg>
    </Spinner>
  );
};

export { LoadingSpinner };
