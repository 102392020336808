import styled from "styled-components";
import { Container, VideoListContainer } from "../common/container";
import { breakpoints } from "../constants/breakpoints";
import { sizes } from "../constants/sizes";

export const VideoDetailHeaderCard = styled.section`
  padding: 16px 0;
`;

export const VideoDetailTitle = styled.h1`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.systemUI};
  color: ${({ theme }) => theme.colors.neutral[900]};
  line-height: 1.5;
  margin: 16px 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  }
`;

export const VideoDetailTagContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 8px;
`;

export const VideoDetailTagItem = styled.li`
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const VideoDetailTagLink = styled.a<{ isNotClickable?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.brand[500]};
  background: ${({ theme }) => theme.colors.brand[100]};
  border-radius: 20px;
  padding: 4px 8px;
  font-weight: bold;
  white-space: break-word;
  text-decoration: none;
  display: block;
  cursor: ${({ isNotClickable }) =>
    isNotClickable ? "not-allowed" : "pointer"};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const VideoDetailStatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
`;

export const VideoDetailsBannerContainer = styled.aside`
  padding: 20px 0;
`;

export const VideoDetailsBanner = styled.div`
  @media ${breakpoints.tablet} {
    max-width: initial;
    display: flex;
    justify-content: center;
  }
`;

export const VideoDetailsContainer = styled(VideoListContainer)`
  @media ${breakpoints.tablet} {
    margin-top: 16px;
  }

  @media ${breakpoints.laptop} {
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-gap: 40px;
  }
`;

export const VideoDetailBannerLink = styled.a`
  @media ${breakpoints.tablet} {
    width: 348px;
  }
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 300px;
`;

export const VideoDetailsPlayerContainer = styled.div`
  max-width: ${sizes.videoPlayerWidth};
  margin: 0 auto;
  /* Add height to prevent layout shift when player is lazy loaded */
  min-height: 200px;
  box-sizing: border-box;
  position: relative;

  @media ${breakpoints.tablet} {
    min-height: 420px;
  }
`;

export const VideoDetailsPlayerWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black};
  pointer-events: auto;
  touch-action: auto;
`;

export const VideoRecommendationsContainer = styled(Container)`
  padding: 0;
`;

export const VideoRecommendationsListContainer = styled.div`
  margin-top: 40px;
`;
export const VideoRecommendationsTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5;
`;

export const MoreVideosContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
