import { ITrackEventArguments } from "./web-checkout-mixpanel-utils.types";
import { ITrackEventpayload } from "../mixpanel-utils.types";
import { isObjectEmpty } from "utils/utils";
import { createSuperPropertiesForTrackingEvent } from "../mixpanel-utils";

export const createPayloadForWebCheckoutTrackingEvent = (
  args: ITrackEventArguments
): ITrackEventpayload => {
  const { userData } = args.useAuthData;
  const {
    Class,
    classValue,
    tenureValue,
    errorReason,
    userFlow,
    stage,
    packageType,
    level,
    failureReason,
    BNPLMobileNumber,
    BNPLName,
    BNPLStudentClass,
    BNPLUserType,
    BNPLLocation
  } = args;

  // super properties
  const payload: ITrackEventpayload =
    createSuperPropertiesForTrackingEvent(args);

  // individual properties for events
  if (level)
    payload.level = userData?.primary_user?.stream
      ? userData?.primary_user.stream
      : null;
  if (classValue)
    payload.class = userData?.primary_user?.class_type
      ? userData?.primary_user.class_type
      : null;
  if (packageType || packageType === null) payload.packageType = packageType;
  if (tenureValue || tenureValue === null) payload.tenureValue = tenureValue;
  if (stage || stage === null) payload.stage = stage;
  if (errorReason || errorReason === null) payload.errorResponse = errorReason;
  if (failureReason || failureReason === null)
    payload.failureReason = failureReason;
  if (Class)
    payload.Class = userData?.primary_user?.grade
      ? userData?.primary_user.grade
      : null;

  // BNPL Properties (cicilian form)
  if (BNPLMobileNumber || BNPLMobileNumber === null) {
    payload["BNPL Mobile Number"] = BNPLMobileNumber;
  }
  if (BNPLName || BNPLName === null) {
    payload["BNPL Name"] = BNPLName;
  }
  if (BNPLStudentClass || BNPLStudentClass === null) {
    payload["BNPL Student Class"] = BNPLStudentClass;
  }
  if (BNPLUserType || BNPLUserType === null) {
    payload["BNPL User Type"] = BNPLUserType;
  }
  if (BNPLLocation || BNPLLocation === null) {
    payload["BNPL Location"] = BNPLLocation;
  }

  // determining user flow
  if (userFlow) {
    if (
      !userData?.student_users?.length &&
      isObjectEmpty(userData?.primary_user) &&
      isObjectEmpty(userData?.parent_user)
    ) {
      payload.userFlow = "New user";
    } else if (
      !isObjectEmpty(userData?.primary_user) &&
      isObjectEmpty(userData?.parent_user) &&
      userData?.primary_user?.role === "student"
    ) {
      payload.userFlow = "Existing student without parent information";
    } else if (
      !isObjectEmpty(userData?.parent_user) &&
      !isObjectEmpty(userData?.primary_user) &&
      userData?.primary_user?.role === "student"
    ) {
      payload.userFlow = "Existing student with parent information";
    } else if (
      !isObjectEmpty(userData?.parent_user) &&
      isObjectEmpty(userData?.primary_user) &&
      userData?.primary_user?.role === "parent"
    ) {
      payload.userFlow = "Existing parent without student information";
    } else if (
      !isObjectEmpty(userData?.parent_user) &&
      !isObjectEmpty(userData?.primary_user) &&
      userData?.primary_user?.role === "parent"
    ) {
      payload.userFlow = "Exisitng parent with student information";
    }
  }

  return payload;
};
