import { ILoadingPageName } from "hooks/pages/use-loading/use-loading.types";

export interface LoaderProps {
  loadingPageName: ILoadingPageName;
}

export enum LoaderPageName {
  tanyaCategory = "tanya/category",
  tanyaVideo = "tanya/video"
}
