export const eventNames = {
  loginButtonClicked: "login button clicked",
  mobileNumberEntered: "mobile number entered",
  correctOTPEntered: "correct otp entered",
  roleSelected: "role selected",
  curriculumSelected: "curriculum selected",
  gradeSelected: "grade selected",
  profileDetailsFilled: "profile details filled",
  studentRegister: "student register",
  studentLogin: "student login",
  studentProfileSwitched: "profile switched",
  logoutButtonClicked: "logout button clicked",
  trialConfirmationPageViewed: "trial confirmation page viewed",
  mobileNumberEnteredScreenViewed: "mobile number entered screen viewed",
  otpEnterScreenViewed: "otp enter screen viewed",
  roleSelectionScreenViewed: "role selection screen viewed",
  createProfileScreenViewed: "create profile screen viewed",
  additionalDetailsScreenViewed: "additional details screen viewed",
  successfulRegisterPopupShown: "successful register popup shown",
  backClickedOnRegisterScreen: "back clicked on register screen",
  submitClickedOnAdditionalDetailsScreen:
    "submit clicked on additional details screen",
  closedSuccessfulRegisterPopup: "closed successful register popup",
  continueClickedOnSuccessfullRegisterPopup:
    "continue clicked on successful register popup",
  backScreenClickedOnSuccessfullRegisterPopup:
    "back screen clicked on successful register popup",
  locationPopupViewed: "enter location popup viewed",
  locationPermissionGranted: "permission granted on enter location popup",
  locationPermissionDenied: "permission denied on enter location popup",
  locationPermissionError: "error toast triggered on location permission",
  FAQExpandButtonClicked: "FAQ Expand Button Clicked",
  noActivePackageForPhoneNumberViewed:
    "no active package on this number viewed",
  askKakakSiagaForHelpClickedOnOTPScreen:
    "ask kakak siaga for help clicked on otp screen",
  parentsNumberOnOTPScreenViewed: "parents number on otp screen viewed",
  okDoneClickedOnParentsNumberOTPScreen:
    "ok done clicked on parents number otp entered",
  smartfestRegistrationPageViewed: "smartfest registration page viewed",
  smartfestRegisterButtonClicked: "smartfest register button clicked",
  smartfestRegisterNotEligibleScreenShown:
    "smartfest register not eligible screen shown",
  smartfestRegisterPaymentLinkGenerated:
    "smartfest register payment link generated",
  smartfestRegistrationFailed: "smartfest registration failed"
};
