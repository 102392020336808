import React, { useState } from "react";
import Image from "next/legacy/image";

import { IAppBannerProps, IBannerObjData } from "./app-banner.types";
import {
  BannerContainer,
  PromptTextContainer,
  CloseButton,
  MobileImageWrapper
} from "./app-banner.styles";

import { Close } from "../icons/close";
import DownloadImage from "public/assets/download-colearn.svg";

const AppBanner = ({ pathName }: IAppBannerProps): JSX.Element | null => {
  const [close, setClose] = useState<boolean>(false);

  const bannerData: IBannerObjData = {
    "/": {
      promptText:
        "Ikut Bimbel CoLearn lewat aplikasi di HP atau website di laptop",
      appURL: "https://colearn.app.link/M0S41Ttqzjb"
    }
  };

  if (close || !bannerData[pathName]) {
    return null;
  } else {
    return (
      <BannerContainer $close={close}>
        <MobileImageWrapper>
          <Image layout="fixed" src={DownloadImage} alt="Download" />
        </MobileImageWrapper>
        <PromptTextContainer>
          <p>{bannerData[pathName].promptText}</p>
        </PromptTextContainer>
        <CloseButton onClick={() => setClose(true)} aria-label="close-button">
          <Close />
        </CloseButton>
      </BannerContainer>
    );
  }
};

export { AppBanner };
