import {
  IParentUser,
  IPrimaryUser,
  IStudentUser
} from "services/api/api.types";
import { UserType } from "types/common/common.types";

export type IUserProfileProps = {
  userData: {
    register_token: string | null;
    student_users: IStudentUser[];
    parent_user: IParentUser;
    primary_user: IPrimaryUser;
    userType: UserType;
  };
  setUserData: React.Dispatch<
    React.SetStateAction<{
      register_token: string | null;
      student_users: IStudentUser[];
      parent_user: IParentUser;
      primary_user: IPrimaryUser;
      userType: UserType;
    }>
  >;
  signOutUser: () => void;
};

export enum SelectedOption {
  ProfileDetails = "profile details",
  SelectProfile = "select profile"
}
