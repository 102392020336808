import styled from "styled-components";
import { FieldError } from "react-hook-form";
import { FormFieldWithError } from "../field-error/field-error.styles";

export const StyledTextArea = styled.textarea<{ error?: FieldError }>`
  height: 100px;
  resize: none;
  background: ${({ theme }) => theme.colors["white"]};
  border: 2px solid
    ${({ theme, error }) => {
      return error?.message ? theme.colors.red[100] : theme.colors.neutral[300];
    }};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px 10px 12px;
  &:focus {
    outline: none;
  }
`;

export const TextAreaDiv = styled(FormFieldWithError)`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  position: relative;
`;
export const WordCount = styled.div`
  position: absolute;
  right: 8px;
  bottom: 12px;
  color: ${({ theme }) => theme.colors.neutral[600]};
`;
