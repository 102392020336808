import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

export const VideoPlayerLoader = styled(Skeleton)`
  width: 100%;
  height: 50vh;
  margin-top: 18px;
`;

export const VideoDataContainer = styled.div`
  margin-top: 16px;
`;
