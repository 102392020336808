import styled from "styled-components";
import { breakpoints } from "styles";
import { Button } from "components/atoms/button/button";
import { Form } from "components/form-component/form/form";

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Dashed = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.neutral[200]};
  margin: 6px 0 6px 0;
`;

export const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin-top: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const FormStyles = styled.div`
  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
`;

export const DataStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;
`;

export const DataHeading = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 10px 0 10px 0;
`;

export const WrapperWidth = styled.span<{ width: string }>`
  width: ${({ width }) => width};
`;

export const FlexGrowWrapper = styled.div`
  flex-grow: 1;
`;

export const AlertWrapper = styled.div`
  margin: 14px 0 10px 0;
`;

export const SpaceWrapper = styled.div`
  margin: 12px 0 10px 0;
  display: flex;
  justify-content: flex-end;
`;

export const LoginSpaceWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  @media ${breakpoints.tablet} {
    margin: 30px 0 10px 0;
  }
`;

export const ButtonCTA = styled(Button)`
  width: 100%;

  @media ${breakpoints.tablet} {
    width: auto;
  }
`;
export const UserProfileButtonGroups = styled.div`
  margin-top: auto;
  @media ${breakpoints.laptop} {
    margin: 12px 0 10px 0;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalText = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin: 8px 0px;
  text-align: center;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  }
`;

export const SearchCityFieldLoader = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 1;
`;

export const SearchCityField = styled.div`
  position: relative;
`;

export const SearchCityFieldInput = styled.div<{ isLoading: boolean }>`
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "auto")};
  touch-action: ${({ isLoading }) => (isLoading ? "none" : "auto")};
`;
