import {
  FeatureDefinition,
  GrowthBookSSRData
} from "@growthbook/growthbook-react";
import wretch from "wretch";
import * as Sentry from "@sentry/react";
import { GetServerSidePropsContext } from "next";
import { constants } from "constants/constants";
import { generateUUID } from "./utils";
import trackEvent from "services/mixpanel/mixpanel";
import { growthbook } from "stores/growthbook/growthbook-context";

const FEATURES_ENDPOINT = process.env.NEXT_PUBLIC_GROWTHBOOK_SDK_ENDPOINT;

export const getGrowthBookFeatures = (): Promise<
  Record<string, FeatureDefinition>
> => {
  return wretch(FEATURES_ENDPOINT)
    .get()
    .json((json) => json.features);
};

export const mergeToGrowthbookAttributes = (
  attributesToMerge: Record<string, unknown>
): Record<string, unknown> => {
  const existingAttributes = growthbook.getAttributes();

  const newAttributes = {
    ...existingAttributes,
    ...attributesToMerge
  };

  growthbook.setAttributes(newAttributes);

  return newAttributes;
};

export async function generateGrowthBookSSRProps({
  req
}: GetServerSidePropsContext): Promise<GrowthBookSSRData> {
  try {
    const authData = JSON.parse(
      req.cookies[constants.userContext.cookieKey] || "{}"
    );
    const mixpanelData = JSON.parse(
      req.cookies[`mp_${process.env.NEXT_PUBLIC_MIXPANEL_TOKEN}_mixpanel`] ||
        "{}"
    );
    const primaryUserSubscriptionType =
      req.cookies[constants.userContext.cookiePrimaryUserSubscriptionType];

    const user_id =
      authData?.primary_user?.id || mixpanelData?.$device_id || generateUUID();

    const attributesToMerge: Record<string, unknown> = {
      user_id,
      is_logged_in: !!authData?.primary_user?.id
    };

    if (authData?.primary_user?.grade) {
      attributesToMerge.user_grade = authData?.primary_user?.grade;
    }

    if (primaryUserSubscriptionType) {
      attributesToMerge.user_type = primaryUserSubscriptionType;
    }

    const attributes = mergeToGrowthbookAttributes(attributesToMerge);

    const features = await getGrowthBookFeatures();
    return {
      features,
      attributes
    };
  } catch (error) {
    Sentry.captureException(error);
    trackEvent({
      eventName: "growthbook initialisation failed",
      payload: {
        err: (error as Error).message
      }
    });
    throw error;
  }
}
