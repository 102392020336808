export const colors = {
  brand: {
    50: "#EBF3FF",
    100: "#DEEBFF",
    200: "#A4C5FE",
    300: "#77A3FC",
    400: "#5587F9",
    500: "#1E5AF6",
    600: "#1545D3",
    700: "#0F32B1",
    800: "#09238E",
    900: "#051876",
    1000: "#131E4E"
  },
  yellow: {
    50: "#FFFBE9",
    100: "#FFF7C9",
    200: "#FEF8B3",
    300: "#FEF38E",
    400: "#FFED76",
    600: "#F49B26",
    500: "#FEE643",
    800: "#937E15",
    900: "#FFF3A8"
  },
  orange: {
    100: "#FFF0E0",
    200: "#FF8A64",
    300: "#E8641F"
  },
  tango: {
    50: "#FFE8E0",
    500: "#F74B1C",
    600: "#C03B17"
  },
  navy: {
    100: "#CDD2FA",
    200: "#9EA7F5",
    300: "#6975E2",
    400: "#424DC5",
    500: "#121DA0",
    600: "#0D1589",
    800: "#213288",
    900: "#19286D",
    1000: "#EAECFE"
  },
  green: {
    50: "#E4FFEE",
    100: "#E1FCE6",
    200: "#C4FAD4",
    300: "#A3F1C2",
    500: "#61D2A4",
    600: "#00CB88",
    700: "#309781",
    800: "#009274",
    900: "#4F801E"
  },
  neutral: {
    50: "#F3FAFF",
    100: "#EAF2FD",
    200: "#D5DFEE",
    300: "#C1C5D0",
    400: "#A4B3CD",
    500: "#9297A1",
    600: "#777D88",
    700: "#5E6C84",
    800: "#393C42",
    900: "#2C2C31",
    1000: "#181B25"
  },
  pink: {
    100: "#FFE5E0",
    500: "#FF688A",
    600: "#DB4C7A"
  },
  magenta: {
    50: "#FFE7EC",
    100: "#FEDBD6",
    300: "#F98489",
    400: "#E02954",
    500: "#EC3360",
    600: "#CA255D"
  },
  white: "#ffffff",
  black: {
    50: "#2C2C31",
    100: "#181B25"
  },
  red: {
    100: "#EC3360"
  },
  blue: {
    50: "#ECF6FF",
    100: "#DBEEFF",
    200: "#A0CBFD",
    400: "#4A83F9",
    600: "#1A48D0",
    700: "#2238A8",
    800: "#1E5AF6"
  },
  periwinkle: {
    100: "#E9F1F7",
    200: "#F2F8FC",
    400: "#dee7f0",
    500: "#D1DAE6",
    600: "#99ABC4",
    700: "#697FA4"
  },
  transparent: {
    white10: "#283c6d1a",
    black70: "#00000070",
    brand15: "#0F32B115"
  },
  aqua: {
    50: "#EEFEFD",
    100: "#D0F7F7",
    600: "#49A0B4"
  }
};

export type IColors = typeof colors;
