import {
  BreadCrumbLoaderContainer,
  BreadCrumbLoaderItem
} from "./bread-crumb-loader.styles";

type BreadCrumbLoaderProps = {
  count: number;
};

const BreadCrumbLoader: React.FC<BreadCrumbLoaderProps> = ({ count }) => (
  <BreadCrumbLoaderContainer>
    {[...Array(count)].map((_, index) => (
      <BreadCrumbLoaderItem key={`breadcrumb-${index}`} />
    ))}
  </BreadCrumbLoaderContainer>
);

export { BreadCrumbLoader };
