import * as Sentry from "@sentry/nextjs";
import { batchEvents } from "pages/_app";

export type EventPayload = Record<
  string,
  string[] | string | number | boolean | null | undefined
>;

const trackEvent = async ({
  eventName,
  payload,
  sendImmediately = false
}: {
  eventName: string;
  payload?: EventPayload;
  sendImmediately?: boolean;
}) => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    //@ts-ignore

    setTimeout(() => {
      batchEvents.forEach((event) => {
        if (mixpanel) {
          mixpanel.track(event.eventName, event.payload);
          batchEvents.shift();
        }
      });
    }, 100);

    if (mixpanel) {
      mixpanel.track(eventName, payload, { send_immediately: sendImmediately });
    } else {
      throw new Error("Mixpanel instance is not initialized");
    }
  } catch (error) {
    batchEvents.push({ eventName, payload });
    console.error(`Failed to capture mixpanel event ${error}`);
  }
};

const registerSuperProperties = async (
  payload: Record<string, string[] | string | number | boolean | null>
) => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    mixpanel.register(payload);
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Failed to set mixpanel super properties ${error}`);
  }
};

const aliasUser = async (userId: string) => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    mixpanel.alias(userId);
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Failed to alias user ${userId} - ${error}`);
  }
};

const identifyUser = async (userId: string) => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    mixpanel.identify(userId);
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Failed to identify user ${userId} - ${error}`);
  }
};

const setUserProperties = async (
  payload: Record<string, string[] | string | number | boolean | null>
) => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    mixpanel.people.set(payload);
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Failed to set mixpanel user properties ${error}`);
  }
};

const unionUserProperties = async (
  payload: Record<string, string[] | string | number | boolean | null>
) => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    mixpanel.people.union(payload);
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Failed to set mixpanel user properties ${error}`);
  }
};

const resetSuperProperties = async () => {
  try {
    const mixpanel = (await import("mixpanel-browser")).default;
    mixpanel.reset();
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Failed to reset mixpanel user properties ${error}`);
  }
};

export default trackEvent;
export {
  registerSuperProperties,
  aliasUser,
  identifyUser,
  setUserProperties,
  unionUserProperties,
  resetSuperProperties
};
