import React from "react";
import { IIcon } from "../icons.types";

const Loading: React.FC<IIcon> = ({
  height = 20,
  width = 20,
  color = "currentColor"
}): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 1a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-3 0v-3A1.5 1.5 0 0112 1zm0 16a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-3 0v-3A1.5 1.5 0 0112 17zm9.5-3.5a1.5 1.5 0 000-3h-3a1.5 1.5 0 000 3h3zM7 12a1.5 1.5 0 01-1.5 1.5h-3a1.5 1.5 0 010-3h3A1.5 1.5 0 017 12zm10.655 7.781a1.5 1.5 0 002.121-2.121l-2.121-2.121a1.5 1.5 0 10-2.121 2.121l2.12 2.121zm-9.19-11.32a1.5 1.5 0 01-2.121 0l-2.121-2.12a1.5 1.5 0 112.12-2.122L8.466 6.34a1.5 1.5 0 010 2.122zm11.32-2.12a1.5 1.5 0 00-2.12-2.122l-2.122 2.122a1.5 1.5 0 102.121 2.121l2.122-2.121zM8.458 15.539a1.5 1.5 0 010 2.121l-2.121 2.122a1.5 1.5 0 11-2.122-2.122l2.122-2.12a1.5 1.5 0 012.12 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export { Loading };
