import React, { useRef } from "react";
import { ModalProps, ModalSize } from "./modal.types";
import {
  ModalStyles,
  OverlayStyles,
  ModalHeading,
  ModalHeader
} from "./modal.styles";
import { Portal } from "components/portal/portal";
import { useClickOutside } from "hooks/use-click-outside/use-click-outside";
import { CrossIcon } from "components/icons/cross-icon";
import { IconButton } from "styles/common/icon-button";
import { useLockBodyScroll } from "hooks/use-lock-body-scroll/use-lock-body-scroll";

const Modal = ({
  children,
  isOpen,
  onClose,
  heading,
  size = ModalSize.Normal,
  displayProperties,
  closeIconProperties,
  closeOnClickOutside = true,
  testId
}: ModalProps): JSX.Element | null => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    closeOnClickOutside && onClose();
  };

  useClickOutside(ref, handleClickOutside);
  useLockBodyScroll(isOpen);

  if (!isOpen) {
    return null;
  }
  return (
    <Portal>
      <OverlayStyles />
      <ModalStyles
        data-testid={testId}
        ref={ref}
        size={size}
        backgroundColor={displayProperties?.backgroundColor}>
        <ModalHeader heading={!!heading}>
          {heading && <ModalHeading>{heading}</ModalHeading>}
          {!closeIconProperties?.isHidden && (
            <IconButton onClick={onClose} data-testid="close-modal">
              <CrossIcon color={closeIconProperties?.color} />
            </IconButton>
          )}
        </ModalHeader>
        {children}
      </ModalStyles>
    </Portal>
  );
};

export { Modal };
