import { useEffect, useState } from "react";
import { breakpoints } from "styles";

const useMediaQuery = (deviceType: "mobile" | "tablet" | "laptop"): boolean => {
  const query = breakpoints[deviceType];
  const mediaQuery =
    typeof window !== "undefined" ? window.matchMedia(query) : null;
  const [match, setMatch] = useState(!!mediaQuery?.matches);

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery?.matches);
    mediaQuery?.addListener(handler);
    return () => mediaQuery?.removeListener(handler);
  }, [mediaQuery]);

  return match;
};

export { useMediaQuery };
