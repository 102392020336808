import { IUserAuthData } from "stores/auth/auth-context.types";
import { isObjectEmpty } from "utils/utils";
import {
  ISuperPropertiesArguments,
  ITrackEventpayload,
  WebPlatform
} from "./mixpanel-utils.types";

const checkIfUserIsOnboarded = (userData: IUserAuthData["userData"]) => {
  if (isObjectEmpty(userData)) {
    return null;
  }
  if (userData?.register_token) {
    return false;
  }
  return true;
};
export const createSuperPropertiesForTrackingEvent = (
  args: ISuperPropertiesArguments
): ITrackEventpayload => {
  const { userData } = args.useAuthData;
  return {
    whatsAppOptIn: args.useAuthData.isWhatsappConsentGiven ? true : false,
    whatsAppConsent: args.useAuthData.isWhatsappConsentGiven ? true : false,
    userType: null,
    userRole: userData?.primary_user?.role
      ? userData?.primary_user?.role
      : null,
    stream: userData?.primary_user?.stream
      ? userData?.primary_user?.stream
      : null,
    referrer: typeof document !== "undefined" ? document.referrer : null,
    name: userData?.primary_user?.full_name
      ? userData?.primary_user?.full_name
      : null,
    mobile: userData?.primary_user?.phone_number
      ? userData?.primary_user?.phone_number
      : null,
    isTablet: null,
    isOnboarded: checkIfUserIsOnboarded(userData),
    id: userData?.primary_user?.id ? userData?.primary_user?.id : null,
    Grade: userData?.primary_user?.grade ? userData?.primary_user?.grade : null,
    focusCount: null,
    focus: null,
    eventSource: "website",
    email: userData?.primary_user?.email ? userData?.primary_user?.email : null,
    curriculum: "2013 Indonesian Curriculum (Revised Version)",
    Class: userData?.primary_user?.class_type
      ? userData?.primary_user?.class_type
      : null,
    source: "web"
  };
};

export const getSourceInfoEvent = (
  platform: string
): "desktopWeb" | "tabletWeb" | "mobileWeb" => {
  return platform === WebPlatform.Desktop
    ? `desktopWeb`
    : platform === WebPlatform.Tablet
    ? `tabletWeb`
    : `mobileWeb`;
};
