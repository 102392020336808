import React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant: ButtonType;
  disabled?: boolean;
  onClick?:
    | (() => void)
    | ((e: React.MouseEvent<HTMLElement>) => void)
    | ((e: React.MouseEvent<HTMLElement>) => Promise<void>);
  isLoading?: boolean;
  loadingText?: string;
  isFullWidth?: boolean;
  size?: ButtonSize;
}

export enum ButtonSize {
  sm = "small",
  md = "medium",
  lg = "large"
}

export type ButtonType = "primary" | "secondary" | "ghost" | "link" | "light";
