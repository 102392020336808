import Image from "next/legacy/image";
import React, { useEffect } from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { bannerIntentEventNames } from "services/mixpanel/tanya-events";
import {
  ContactMeFormMetaData,
  ContactMeLeadSource
} from "../use-contact-me.types";
import {
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalImageContainer
} from "./contact-me-success.styles";

interface IContactMeSuccessProps {
  onCloseClick: () => void;
  metaData: ContactMeFormMetaData;
  leadSource: ContactMeLeadSource | null;
}

export const ContactMeSuccess: React.FC<IContactMeSuccessProps> = ({
  onCloseClick,
  metaData,
  leadSource
}) => {
  useEffect(() => {
    trackEvent({
      eventName: bannerIntentEventNames.leadSuccessScreenViewed,
      payload: { leadSource, ...metaData }
    });
  }, []);

  return (
    <div data-testid="colearn-bakal-hubungi-kamu-popup">
      <ModalHeader>
        <ModalImageContainer>
          <Image
            src="/assets/coco-whatsapp.svg"
            layout="fill"
            alt="Successfully submitted"
            priority
          />
        </ModalImageContainer>
      </ModalHeader>
      <ModalContent>
        <ModalHeading>
          Oke, CoLearn bakal hubungi kamu buat kasih infonya, ya 😉
        </ModalHeading>
      </ModalContent>
      <ModalFooter>
        <ModalButton
          data-testid="oke-sip-button"
          variant="primary"
          onClick={onCloseClick}
        >
          Oke, sip!
        </ModalButton>
      </ModalFooter>
    </div>
  );
};
