import React from "react";
import type { IIcon } from "./icons.types";

const Close: React.FC<IIcon> = ({
  width = 20,
  height = 20,
  color = "currentColor"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2501 9.84448L17.0834 6.01115C17.5834 5.51115 17.5834 4.67782 17.0834 4.17782L16.5001 3.59448C16.0001 3.09448 15.1667 3.09448 14.6667 3.59448L10.8334 7.42782C10.4167 7.84448 9.66675 7.84448 9.25008 7.42782L5.33341 3.59448C4.83341 3.09448 4.00008 3.09448 3.50008 3.59448L2.91675 4.17782C2.41675 4.67782 2.41675 5.51115 2.91675 6.01115L6.75008 9.84448C7.16675 10.2611 7.16675 11.0111 6.75008 11.4278L2.91675 15.2611C2.41675 15.7611 2.41675 16.5945 2.91675 17.0945L3.50008 17.6778C4.00008 18.1778 4.83341 18.1778 5.33341 17.6778L9.16675 13.8445C9.58341 13.4278 10.3334 13.4278 10.7501 13.8445L14.5834 17.6778C15.0834 18.1778 15.9167 18.1778 16.4167 17.6778L17.0001 17.0945C17.5001 16.5945 17.5001 15.7611 17.0001 15.2611L13.2501 11.5111C12.8334 11.0111 12.8334 10.3445 13.2501 9.84448Z"
      fill={color}
    />
  </svg>
);

export { Close };
