import styled from "styled-components";

export const ErrorLabel = styled.p`
  margin: 5px 0 0px 0;
  position: absolute;
  color: ${({ theme }) => theme.colors.red[100]};
`;

export const FormFieldWithError = styled.div`
  position: relative;
  margin-bottom: 12px;
`;
