import React, { useContext, useReducer } from "react";
import {
  ToastProviderType,
  ToastContextType,
  ToastPropsType
} from "./toast.types";
import { toastReducer, initialState } from "./reducer/toast-reducer";
import { Toast } from "./toast";
import { generateUUID } from "utils/utils";

export const ToastContext = React.createContext({} as ToastContextType);

export const useToast = (): ToastContextType => useContext(ToastContext);

const ToastProvider = ({ children }: ToastProviderType): JSX.Element => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const addToast = (toast: ToastPropsType) => {
    const id = toast.id || generateUUID();
    dispatch({
      type: "ADD_TOAST",
      payload: { ...toast, id }
    });
  };

  const removeToast = (id: string) => {
    dispatch({
      type: "REMOVE_TOAST",
      payload: id
    });
  };

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast
      }}>
      {children}

      {state.toasts.map((toast) => {
        const { id } = toast;
        return (
          <Toast key={id} {...toast} onCloseToast={() => removeToast(id)} />
        );
      })}
    </ToastContext.Provider>
  );
};

export { ToastProvider };
