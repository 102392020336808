import React from "react";
import { StyledInput, InputDiv } from "./input.styles";
import { FieldValues, useController } from "react-hook-form";
import { FieldError } from "../field-error/field-error";
import { Label } from "../label/label";
import { InputProps } from "./input.types";

const Input = <T extends FieldValues>({
  control,
  placeholder,
  label,
  name,
  onBlur,
  testId
}: InputProps<T>): JSX.Element => {
  const {
    field,
    fieldState,
    fieldState: { error }
  } = useController({
    control,
    name
  });

  return (
    <InputDiv>
      {label && <Label id={field.name}>{label}</Label>}
      <StyledInput
        data-testid={testId}
        placeholder={placeholder}
        {...field}
        {...fieldState}
        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
          onBlur && onBlur(e);
          field.onBlur();
        }}
      />
      {error?.message && <FieldError message={error.message} />}
    </InputDiv>
  );
};

export { Input };
