import React from "react";
import Image from "next/legacy/image";
import Link from "next/link";

import { LogoWrapper } from "./logo.styles";
import colearnLogo from "public/assets/colearn_logo.svg";

const Logo: React.FC = () => (
  <Link href="/" passHref>
    <LogoWrapper>
      <Image
        src={colearnLogo}
        alt="Colearn Logo"
        objectFit="contain"
        layout="fill"
      />
    </LogoWrapper>
  </Link>
);

export { Logo };
