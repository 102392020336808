import styled from "styled-components";
import { FieldError } from "react-hook-form";
import { FormFieldWithError } from "../field-error/field-error.styles";

export const StyledInput = styled.input<{ error?: FieldError }>`
  height: 40px;
  border: 2px solid
    ${({ theme, error }) => {
      return error?.message
        ? theme.colors.red[100]
        : theme.colors.periwinkle[500];
    }};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 8px;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

export const InputDiv = styled(FormFieldWithError)`
  padding: 8px 0px;
`;
