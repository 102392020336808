import React from "react";
import { LabelText } from "./label.styles";

const Label = ({
  id,
  children
}: {
  id: string;
  children: React.ReactNode;
}): JSX.Element => {
  return <LabelText htmlFor={id}>{children}</LabelText>;
};

export { Label };
