import React from "react";
import type { IIcon } from "./icons.types";

const IndoFlag: React.FC<IIcon> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7581 7.39493H0.234375V12.7482H15.7581V7.39493Z" fill="white" />
    <path
      d="M16 12.9893H0V2.66675H16V12.9893ZM0.477434 12.5083H15.5166V3.14657H0.477434V12.5083Z"
      fill="#D0D2D3"
    />
    <path
      d="M15.5564 3.11108H0.445312V7.9451H15.5564V3.11108Z"
      fill="#F40010"
    />
  </svg>
);

export { IndoFlag };
