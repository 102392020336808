import React, { PropsWithChildren } from "react";
import { useRouter } from "next/router";

import { Footer } from "../footer/footer";
import { Header } from "../header/header";
import { MainLayout } from "./layout.styles";
import { AppBanner } from "components/app-banner/app-banner";
import { constants } from "constants/constants";

const { WEB_CHECKOUT_PATHS } = constants;

interface ILayoutProps {
  pageProps: Record<string, string | number | Record<string, unknown>>;
}

const Layout: React.FC<PropsWithChildren<ILayoutProps>> = ({ children, pageProps }) => {
  const router = useRouter();
  const isContactMePage = router.pathname.includes("/contact-me");

  const hideMenu =
    WEB_CHECKOUT_PATHS.includes(router.pathname) || isContactMePage;
  const routerQueryLength = Object.keys(router.query).length;
  const hideHeader =
    isContactMePage ||
    (hideMenu &&
      (routerQueryLength === 0 || routerQueryLength === 1) &&
      !(
        router.pathname.includes("/paket") ||
        router.pathname.includes("/payment") ||
        router.pathname.includes("/slot-selection")
      ));
  const hideFooter = router.pathname.includes("/slot-selection");

  return (
    <>
      <MainLayout>
        <AppBanner pathName={router.pathname} />
        {!hideHeader && <Header hidesMenu={hideMenu} pageProps={pageProps} />}
        {children}
        {hideMenu || hideFooter ? null : <Footer />}
      </MainLayout>
    </>
  );
};

export { Layout };
