import { TanyaContextType } from "./tanya-context.types";
import React, { createContext, useContext, useState } from "react";

export const TanyaContext = createContext<TanyaContextType>(
  {} as TanyaContextType
);

export const useTanyaContext = (): TanyaContextType => useContext(TanyaContext);

export function TanyaProvider({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [contactMeFormData, setContactMeFormData] = useState<
    Pick<TanyaContextType["contactMeForm"], "show" | "metaData">
  >({
    show: false,
    metaData: {}
  });

  return (
    <TanyaContext.Provider
      value={{
        contactMeForm: {
          ...contactMeFormData,
          setContactMeFormData
        }
      }}>
      {children}
    </TanyaContext.Provider>
  );
}
