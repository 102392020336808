import { Button } from "components/atoms/button/button";
import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";

export const ModalHeader = styled.div`
  text-align: center;
`;

export const ModalImageContainer = styled.div`
  width: 144px;
  height: 144px;
  position: relative;
  margin: auto;
`;

export const ModalContent = styled.div`
  margin: 24px 0;
  text-align: center;

  @media ${breakpoints.mobile} {
    margin: 20px 0;
  }
`;

export const ModalHeading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  color: ${({ theme }) => theme.colors.neutral[1000]};
  line-height: 36px;
  margin: 0;

  @media ${breakpoints.mobile} {
    line-height: 28px;
    font-size: ${rem(22)};
  }
`;

export const ModalFooter = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const ModalFooterNote = styled.div`
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  line-height: 20px;
  color: #72839e;
  margin-top: 16px;
`;

export const ModalButton = styled(Button)`
  width: 280px;
`;

export const Counter = styled.span`
  color: ${({ theme }) => theme.colors.neutral[1000]};
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  font-family: ${({ theme }) => theme.fonts.secondary};
`;
