const zIndex = {
  header: 1000,
  videoBlockMessage: 10,
  videoPlayerControls: 10,
  videoPlayerControlsBackground: 1,
  floatingButton: 1000,
  selectInput: 1001,
  searchFilter: 1001,
  modal: 1000,
  floatingCTA: 1000,
  callMeBackCTA: 1000,
  toast: 10000,
  dropdownList: 1,
  stickySelectedPackage: 1000,
  leftAndRightScrollIconsInModal: 1009,
  videoPlayerProgress: 10,
  videoPlayerActiveProgress: 1,
  videoPlayerSpeed: 100,
  videoPlayerVerified: 1002,
  videoPlayerLoader: 101,
  videoCardOverlay: 10,
  videoCardPlayIcon: 100,
  pushToAppCTA: 102,
  videoCardDuration: 100,
  videoBlockerWrapper: 100,
  verifiedVideoTooltip: 10000,
  cameraIconTooltip: 10000,
  kelasLiveBanner: 9,
  powerUpBanner: 11,
  verifiedVideoModalBg: 1001,
  verifiedVideoModal: 1002,
  tanyaAdsFreePushToAppModalBg: 1001,
  tanyaAdsFreePushToAppModal: 1002
};

export { zIndex };
