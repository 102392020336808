import {
  FormWrapper,
  JoiningReasonModalHeadingContainer,
  JoiningReasonModalSubHeading,
  ModalBody,
  ModalHeading,
  HeadingContainer,
  ModalSubHeading
} from "./contact-me.styles";
import React from "react";
import { useContactMe } from "components/contact-me/use-contact-me";
import { ContactMeUserDetailsForm } from "./contact-me-form/contact-me-user-details-form";
import { ContactMeSuccess } from "./contact-me-success/contact-me-success";
import { useMediaQuery } from "hooks/use-media-query/use-media-query";
import { Modal } from "components/modal/modal";
import { ModalSize } from "components/modal/modal.types";
import { ContactMeFormSteps, IContactMeProps } from "./use-contact-me.types";
import { ContactMeJoiningReasonForm } from "./contact-me-form/contact-me-joining-reason-form";
import close from "public/assets/blueicon.svg";
import Image from "next/image";
const ContactMe: React.FC<IContactMeProps> = ({
  onClose,
  metaData = {},
  leadSource,
  buttonCTAText
}) => {
  const isMobile = useMediaQuery("mobile");

  const {
    control,
    isValid,
    handleSubmit,
    isLoading,
    onUserDetailsFormSubmit,
    onJoiningReasonFormSubmit,
    formStep,
    getValues,
    handleClose,
    handleSuccessClose
  } = useContactMe({ metaData, onClose, leadSource });

  const renderFormStep = () => {
    switch (formStep) {
      case ContactMeFormSteps.DETAILS_ENTERED_SUCCESSFULLY: {
        return (
          <ContactMeSuccess
            onCloseClick={handleSuccessClose}
            metaData={metaData}
            leadSource={leadSource}
          />
        );
      }
      case ContactMeFormSteps.CAPTURE_USER_DETAILS: {
        return (
          <div data-testid="dapetin-info-bimbel-form">
            <HeadingContainer>
              <ModalHeading>Dapetin Info Bimbel CoLearn</ModalHeading>
              <Image
                src={close}
                alt="bullet"
                width={24}
                height={24}
                onClick={handleClose}
              />
            </HeadingContainer>
            <ModalSubHeading>
              Isi data dan CoLearn akan hubungi buat kirim infonya 🤗
            </ModalSubHeading>
            <FormWrapper>
              <ContactMeUserDetailsForm
                control={control}
                isValid={isValid}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                onSubmit={onUserDetailsFormSubmit}
                onSkip={handleClose}
                getValues={getValues}
                metaData={metaData}
                leadSource={leadSource}
                buttonCTAText={buttonCTAText}
              />
            </FormWrapper>
          </div>
        );
      }
      case ContactMeFormSteps.CAPTURE_REASON_FOR_JOINING: {
        return (
          <div data-testid="kendala-belajar-form">
            <JoiningReasonModalHeadingContainer>
              <ModalHeading>
                Apa kendala belajarmu? Bimbel CoLearn bisa bantu
              </ModalHeading>
              <JoiningReasonModalSubHeading>
                Kita bakal cariin solusi belajar terbaik buatmu
              </JoiningReasonModalSubHeading>
            </JoiningReasonModalHeadingContainer>
            <FormWrapper>
              <ContactMeJoiningReasonForm
                onSubmit={onJoiningReasonFormSubmit}
                onSkip={handleClose}
                metaData={metaData}
                leadSource={leadSource}
              />
            </FormWrapper>
          </div>
        );
      }
    }
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      size={
        isMobile && formStep === ContactMeFormSteps.CAPTURE_USER_DETAILS
          ? ModalSize.Normal
          : ModalSize.Narrow
      }
      closeIconProperties={{
        isHidden: true
      }}
    >
      <ModalBody>{renderFormStep()}</ModalBody>
    </Modal>
  );
};

export default ContactMe;
