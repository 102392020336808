import styled from "styled-components";
import { breakpoints } from "styles";
import { Button } from "components/atoms/button/button";

export const ProfileSelectionContainer = styled.div<{ isUIBlocked: boolean }>`
  display: grid;
  margin-top: 16px;
  gap: 10px;
  grid-template-columns: 1fr;
  pointer-events: ${({ isUIBlocked }) => (isUIBlocked ? "none" : "initial")};

  @media ${breakpoints.laptop} {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 34px;
    gap: 24px;
  }
`;

export const ProfileContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  cursor: pointer;
  border: ${({ isSelected, theme }) =>
    isSelected
      ? `1.5px solid ${theme.colors.brand["500"]}`
      : `1.5px solid ${theme.colors.periwinkle["500"]}`};
  border-radius: 6px;
  padding: 12px;
  gap: 16px;
  max-height: 88px;

  @media ${breakpoints.laptop} {
    padding: 16px;
  }
`;

export const ProfileDesktopAvatar = styled.div`
  display: none;

  @media ${breakpoints.laptop} {
    border-radius: 12px;
    display: inline-block;
  }
`;

export const ProfileMobileAvatar = styled.div`
  border-radius: 12px;
  display: inline-block;

  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const ProfileDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ProfileDetailsItem = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.neutral["600"]};
  line-height: 20px;

  &:first-child {
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.neutral["900"]};
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 2px;

    @media ${breakpoints.laptop} {
      line-height: 28px;
      font-size: ${({ theme }) => theme.fontSizes.lg};

      &:first-child {
        width: 140px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

export const SelectProfileButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: center;
  flex-grow: 1;

  @media ${breakpoints.laptop} {
    margin-top: 32px;
    justify-content: flex-end;
  }
`;

export const SelectProfileButton = styled(Button)`
  width: 100%;
  @media ${breakpoints.laptop} {
    width: auto;
  }
`;
