import { Button } from "components/atoms/button/button";
import styled from "styled-components";
import { Form } from "components/form-component/form/form";

import { breakpoints } from "styles";

export const ButtonContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const SubmitButton = styled(Button)`
  width: 280px;
  @media ${breakpoints.mobile} {
    display: block;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media ${breakpoints.mobile} {
    gap: 5px;
  }
`;

export const StyledForm = styled(Form)`
  height: 100%;
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  margin: auto;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: ${({ theme }) => theme.fonts.secondary};
`;
