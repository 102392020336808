import React, { useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useCookies } from "react-cookie";
import {
  getGrowthBookFeatures,
  mergeToGrowthbookAttributes
} from "utils/growthbook-utils";
import { unionUserProperties } from "../../services/mixpanel/mixpanel";
import { generateUUID } from "utils/utils";
import { useAuth } from "stores/auth/auth-context";
import { IPrimaryUser } from "services/api/api.types";
import { constants } from "constants/constants";

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  enableDevMode: process.env.NODE_ENV === "development" ? true : false,
  attributes: {
    platform: "web"
  },
  trackingCallback: (experiment, result) => {
    // Prevent firing event on server side
    if (typeof window === "undefined") {
      return;
    }
    unionUserProperties({
      growthbook_experiments: `${experiment.key}_${experiment.key}.${result.variationId}`
    });
  }
});

const GrowthBookContext = React.createContext({
  growthbook
});

const GrowthBookContextProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const {
    user: { userData }
  } = useAuth();

  const [cookies] = useCookies();

  const mixpanelData =
    cookies[`mp_${process.env.NEXT_PUBLIC_MIXPANEL_TOKEN}_mixpanel`];
  const primaryUserSubscriptionType =
    cookies[constants.userContext.cookiePrimaryUserSubscriptionType];

  const getUserId = (primary_user?: IPrimaryUser) => {
    return primary_user?.id || mixpanelData?.$device_id || generateUUID();
  };

  const init = async (primary_user?: IPrimaryUser) => {
    try {
      const features = await getGrowthBookFeatures();
      const user_id = getUserId(primary_user);
      growthbook.setFeatures(features);
      const attributes: Record<string, unknown> = {
        user_id,
        is_logged_in: !!primary_user?.id
      };
      if (primary_user?.grade) {
        attributes.user_grade = primary_user?.grade;
      }
      if (primaryUserSubscriptionType) {
        attributes.user_type = primaryUserSubscriptionType;
      }
      mergeToGrowthbookAttributes(attributes);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    init(userData?.primary_user);
  }, [userData?.primary_user]);

  const contextValue = useMemo(() => {
    return {
      growthbook
    };
  }, []);

  return (
    <GrowthBookContext.Provider value={contextValue}>
      <GrowthBookProvider growthbook={growthbook}>
        {children}
      </GrowthBookProvider>
    </GrowthBookContext.Provider>
  );
};

const useGrowthbookContext = () => useContext(GrowthBookContext);

export {
  GrowthBookContext,
  GrowthBookContextProvider,
  useGrowthbookContext,
  growthbook
};
