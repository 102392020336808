import { createGlobalStyle } from "styled-components";
import { IThemeWrapper } from "./theme";
import { normalize } from "polished";
import { rootFontSize } from "./constants/typography";
import fontsCSS from "./css/fonts.module.css";

const GlobalStyle = createGlobalStyle<IThemeWrapper>`
  ${normalize()}

  html, body, #__next {
    height: 100%;
  }

  html {
    font-size: ${rootFontSize};
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.secondary};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  ${fontsCSS}

`;

export { GlobalStyle };
