import React from "react";
import {
  StyledEditableInput,
  InputWithActionWrapper,
  EditButton
} from "./mobile-input.styles";
import { FlagDiv, Wrapper } from "../verified-input/verified-input.styles";
import { FieldError } from "../field-error/field-error";
import { IndoFlag } from "components/icons/indo-flag";
import { Label } from "../label/label";
import { IMobileInputEditableProps } from "./mobile-input.types";
import { Edit } from "components/icons/edit";
import { FieldValues, useController } from "react-hook-form";
import { EditableInputDiv } from "../mobile-input-editable/mobile-input-editable.styles";
import { SmallText } from "components/web-checkout/user-profile-form/user-profile-form.styles";

const MobileInput = <T extends FieldValues>({
  name,
  label,
  isDisabled = false,
  control,
  editAction,
  placeholder,
  hint,
  onBlur
}: IMobileInputEditableProps<T>): JSX.Element => {
  const {
    field,
    fieldState,
    fieldState: { error }
  } = useController({
    control,
    name
  });
  const renderHint = () => {
    if (hint && hint.length > 0 && !error?.message)
      return <SmallText>{hint}</SmallText>;
    else if (error?.message) {
      return <FieldError message={error.message} />;
    } else return <></>;
  };
  return (
    <EditableInputDiv>
      {label && <Label id={field.name}>{label}</Label>}
      <Wrapper>
        <span>
          <FlagDiv>
            <IndoFlag /> +62
          </FlagDiv>
        </span>
        <InputWithActionWrapper>
          <StyledEditableInput
            placeholder={placeholder}
            data-testid="mobile-login-field"
            disabled={isDisabled}
            {...field}
            {...fieldState}
            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
              onBlur && onBlur(e);
              field.onBlur();
            }}
          />
          {isDisabled && editAction && (
            <EditButton
              type="button"
              onClick={() => editAction()}
              data-testid="mobile-login-field-edit-button">
              <Edit />
            </EditButton>
          )}
        </InputWithActionWrapper>
      </Wrapper>
      {renderHint()}
    </EditableInputDiv>
  );
};
export { MobileInput };
