import { Modal } from "components/modal/modal";
import { ProfileSelection } from "components/web-checkout/profile-selection/profile-selection";
import { ISelectProfileModal } from "./select-profile-modal.types";

const SelectProfileModal: React.FC<ISelectProfileModal> = ({
  isOpen,
  onClose,
  heading,
  userData,
  setUserData
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} heading={heading}>
      <ProfileSelection
        userData={userData}
        setUserData={setUserData}
        onClose={onClose}
      />
    </Modal>
  );
};

export { SelectProfileModal };
