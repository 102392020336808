import { IUserAuthData } from "stores/auth/auth-context.types";

export interface ISuperPropertiesArguments {
  useAuthData: IUserAuthData;
}

export interface ITrackEventpayload {
  [key: string]: string | string[] | boolean | null | Document["referrer"];
}

export enum WebPlatform {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop"
}
