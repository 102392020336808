import { Modal } from "components/modal/modal";
import { IProfileDetailModal } from "./profile-deatils-modal.types";
import {
  DashedLine,
  LogoutButton,
  PersonalDataContainer,
  PersonalDataItem,
  ProfileDetailFooter,
  ProfileDetailSubHeading,
  UserAvatar
} from "./profile-details-modal.styles";
import userProfileImage from "public/assets/home/default-user-profile.png";
import { isObjectEmpty } from "utils/utils";
import trackEvent from "services/mixpanel/mixpanel";
import { eventNames } from "services/mixpanel/events";
import { getPageNameFromPath } from "utils/utils";
import { useRouter } from "next/router";

const ProfileDetailsModal: React.FC<IProfileDetailModal> = ({
  isOpen,
  onClose,
  heading,
  userData,
  signOutUser
}) => {
  const { primary_user: primaryUser, parent_user: parentUser } = userData;
  const router = useRouter();

  const handleLogout = () => {
    trackEvent({
      eventName: eventNames.logoutButtonClicked,
      payload: {
        eventSource: "website",
        logoutFromPage: getPageNameFromPath(router.pathname)
      }
    });
    signOutUser();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} heading={heading}>
      <ProfileDetailSubHeading>Data diri</ProfileDetailSubHeading>
      <PersonalDataContainer>
        <UserAvatar
          src={primaryUser?.avatar_url || userProfileImage}
          alt={primaryUser.full_name}
          objectFit="cover"
          layout="fixed"
          height="60"
          width="60"
        />
        <PersonalDataItem isBold>{primaryUser.full_name}</PersonalDataItem>
        <PersonalDataItem>
          {primaryUser.country_code} {primaryUser.phone_number}
        </PersonalDataItem>
        <PersonalDataItem>
          {primaryUser.class_type} {primaryUser.grade} {primaryUser.stream}
        </PersonalDataItem>
        <PersonalDataItem>{primaryUser.email}</PersonalDataItem>
      </PersonalDataContainer>

      <DashedLine />
      {!isObjectEmpty(parentUser) && (
        <div>
          <ProfileDetailSubHeading>Data orangtua</ProfileDetailSubHeading>
          <PersonalDataContainer>
            <PersonalDataItem isBold noPaddingTop>
              {parentUser?.name}
            </PersonalDataItem>
            <PersonalDataItem>
              {parentUser?.country_code} {parentUser?.phone_number}
            </PersonalDataItem>
          </PersonalDataContainer>
        </div>
      )}

      <ProfileDetailFooter>
        Mau keluar dari profil?
        <LogoutButton onClick={handleLogout} data-testid="logout-button">
          Keluar
        </LogoutButton>
      </ProfileDetailFooter>
    </Modal>
  );
};

export { ProfileDetailsModal };
