import { constants } from "constants/constants";
import { useRouter } from "next/router";
import { getLocalStorage, removeLocalStorage } from "services/local-storage";
import { isObjectEmpty } from "utils/utils";

export const useRedirectAfterLogin = (): { redirectAfterLogin: () => void } => {
  const router = useRouter();

  const redirectAfterLogin = async () => {
    const loggedInFromPage = getLocalStorage(constants.LOGGED_IN_FROM_PAGE) as {
      href: string;
    };

    router.push({
      pathname: "/bimbel",
      query: router.query
    });

    if (!isObjectEmpty(loggedInFromPage)) {
      removeLocalStorage(constants.LOGGED_IN_FROM_PAGE);
    }
  };

  return {
    redirectAfterLogin
  };
};
