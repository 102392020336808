import { rem } from "polished";
import styled from "styled-components";
import { breakpoints, sizes } from "../";

const Container = styled.div`
  max-width: ${sizes.containerWidth};
  padding: 0 ${rem(30)};
  @media ${breakpoints.tablet} {
    margin: 0 auto;
    padding: 0 ${rem(16)};
  }
`;

const MarginContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  max-width: ${sizes.containerWidth};
  margin: 0 ${rem(30)};
  @media ${breakpoints.tablet} {
    margin: 0 auto;
    padding: 0 ${rem(16)};
  }
`;

const VideoListContainer = styled(Container)`
  padding: 0 ${rem(16)};
`;

export { Container, VideoListContainer, MarginContainer };
