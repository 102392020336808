import React from "react";

const PremiumIcon = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99999 0.666656C7.35182 0.666656 5.74065 1.1554 4.37024 2.07108C2.99983 2.98675 1.93173 4.28824 1.301 5.81096C0.670267 7.33368 0.505239 9.00923 0.826783 10.6257C1.14833 12.2423 1.942 13.7271 3.10744 14.8925C4.27287 16.058 5.75773 16.8517 7.37424 17.1732C8.99075 17.4947 10.6663 17.3297 12.189 16.699C13.7117 16.0683 15.0132 15.0002 15.9289 13.6297C16.8446 12.2593 17.3333 10.6482 17.3333 8.99999C17.3333 7.90564 17.1178 6.82201 16.699 5.81096C16.2802 4.79991 15.6664 3.88125 14.8925 3.10743C14.1187 2.33361 13.2001 1.71978 12.189 1.30099C11.178 0.882205 10.0943 0.666656 8.99999 0.666656ZM13.6833 8.99999L12.9333 12.3333C12.8859 12.5405 12.7692 12.7252 12.6025 12.8571C12.4358 12.9889 12.2292 13.0599 12.0167 13.0583H5.99166C5.77849 13.0588 5.57137 12.9875 5.40359 12.856C5.23582 12.7245 5.11715 12.5404 5.06666 12.3333L4.30833 8.99999C4.26658 8.80517 4.28499 8.60227 4.36113 8.41815C4.43728 8.23403 4.56755 8.07739 4.73471 7.96896C4.90186 7.86053 5.09801 7.80544 5.29718 7.81099C5.49634 7.81653 5.68913 7.88244 5.84999 7.99999L6.73333 8.64999L8.39999 5.31666C8.45464 5.2138 8.53625 5.12775 8.63608 5.06774C8.73591 5.00773 8.85019 4.97603 8.96666 4.97603C9.08314 4.97603 9.19741 5.00773 9.29724 5.06774C9.39707 5.12775 9.47868 5.2138 9.53333 5.31666L11.2 8.64999L12.0333 8.01666C12.2163 7.87458 12.3927 7.76305 12.6111 7.73627C12.8295 7.70948 13.0505 7.75607 13.2395 7.8687C13.4285 7.98132 13.5747 8.15359 13.655 8.3584C13.7354 8.5632 13.7453 8.78891 13.6833 8.99999Z"
        fill="#FFD12E"
      />
    </svg>
  );
};

export { PremiumIcon };
