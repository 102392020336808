import { ToastType, ToastInitialStateType, Action } from "../toast.types";

export const initialState = {
  toasts: []
};

export const toastReducer = (
  state: { toasts: ToastType[] },
  action: Action
): ToastInitialStateType => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [...state.toasts, action.payload]
      };
    case "REMOVE_TOAST":
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload)
      };

    default:
      return state;
  }
};
