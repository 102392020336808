import styled from "styled-components";
import { breakpoints } from "styles";

export const Wrapper = styled.section`
  width: fit-content;
  display: flex;
  justify-content: center;
`;

export const DownloadAppImageWrapper = styled.a`
  display: block;
  position: relative;
  width: 140px;
  height: 40px;
  transition: 0.3s ease-in-out;
  @media ${breakpoints.laptop} {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const DownloadAppContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  @media ${breakpoints.laptop} {
    justify-content: flex-start;
  }
  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CheckIGButton = styled.a`
  display: flex;
  justify-content: center;
  width: 250px;
  margin-top: 24px;
  text-align: center;
  cursor: pointer;
  gap: 8px;
  padding: 14px 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue["800"]};
  border-radius: 6px;
  font-weight: 700;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  @media ${breakpoints.laptop} {
    &:hover {
      transform: scale(1.1);
    }
  }
  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    margin-top: 20px;
  }
`;
