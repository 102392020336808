import styled from "styled-components";
import { breakpoints } from "styles";

export const BannerContainer = styled.div<{ $close: boolean }>`
  @media ${breakpoints.laptop} {
    display: none;
  }
  display: flex;
  background-color: ${({ theme }) => theme.colors.yellow[200]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 16px;
  padding: 0 10px;
  gap: 5px;

  @media ${breakpoints.mobile} {
    gap: 0;
  }
`;

export const PromptTextContainer = styled.div`
  align-self: center;
`;

export const AppLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.brand[500]};

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.brand[500]};
  }
  margin-left: 10px;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.yellow[200]};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const MobileImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
