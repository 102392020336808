import { useState } from "react";
import {
  ProfileContainer,
  ProfileDetailsItem,
  ProfileDetailsContainer,
  ProfileSelectionContainer,
  SelectProfileButtonContainer,
  SelectProfileButton,
  ProfileDesktopAvatar,
  ProfileMobileAvatar
} from "./profile-selection.styles";
import userProfileImage from "public/assets/home/default-user-profile.png";
import { IProfileSelectionProps } from "./profile-selection.types";
import Image from "next/legacy/image";
import { switchPrimaryUser } from "services/api/users/user-services";
import { useToast } from "components/atoms/toast/toast-context";
import { getUserProfiles } from "services/api/users/user-services";
import { IPrimaryAuthData } from "services/api/auth/authService.types";
import trackEvent, {
  identifyUser,
  registerSuperProperties,
  setUserProperties
} from "services/mixpanel/mixpanel";
import { mergeArraysByKey } from "utils/utils";
import { eventNames } from "services/mixpanel/events";
import { useAuth } from "stores/auth/auth-context";
import { useCookies } from "react-cookie";
import { constants } from "../../../constants/constants";
import { useKelasLiveContext } from "stores/kelas-live/kelas-live-context";
import { useRedirectAfterLogin } from "hooks/use-redirect-after-login/use-redirect-after-login";
import * as Sentry from "@sentry/nextjs";

const ProfileSelection: React.FC<IProfileSelectionProps> = ({
  userData,
  setUserData,
  isLoginPage = false,
  onClose
}) => {
  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(
    null
  );
  const [isSwitchingProfile, setIsSwitchingProfile] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie, removeCookie] = useCookies();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { userSubscription } = useKelasLiveContext();
  const { redirectAfterLogin } = useRedirectAfterLogin();

  const handleCookieUpdate = (key: string, data: object) => {
    removeCookie(key, { path: "/" });
    setCookie(key, JSON.stringify(data), { path: "/" });
  };

  const updatePrimaryUser = async () => {
    try {
      if (selectedProfileID !== null) {
        setIsSwitchingProfile(true);
        const { access_token, refresh_token, expires_in, token_type } =
          await switchPrimaryUser(
            selectedProfileID,
            userData.primary_user.access_token
          );

        const studentDetails = userData.student_users.find(
          (student) => student.id === selectedProfileID
        );

        const updatedPrimaryUser = {
          ...userData.primary_user,
          ...studentDetails,
          access_token,
          refresh_token,
          expires_in,
          token_type
        };

        const userResponse = await getUserProfiles(access_token);
        const { data: userDataResponse } = userResponse;

        const newUserData = {
          ...userData,
          ...userDataResponse,
          primary_user: {
            ...updatedPrimaryUser,
            ...userDataResponse.primary_user
          },
          student_users: mergeArraysByKey(
            userData.student_users,
            userDataResponse.student_users,
            "id"
          ),
          parent_user: {
            ...userData.parent_user,
            ...userDataResponse.parent_user
          }
        };

        if (newUserData) {
          // Split the cookie because of the cookie size limit
          const primaryAuthData: IPrimaryAuthData = { ...newUserData };
          delete primaryAuthData.student_users;
          delete primaryAuthData.parent_user;
          handleCookieUpdate(constants.userContext.cookieKey, primaryAuthData);
          handleCookieUpdate(constants.userContext.cookieKeyStudent, {
            student_users: newUserData.student_users
          });
          handleCookieUpdate(constants.userContext.cookieKeyParent, {
            parent_user: newUserData.parent_user
          });
          setUserData(newUserData);
        }
        identifyUser(newUserData.primary_user.id);
        registerSuperProperties({
          userRole: "primary_user",
          name: newUserData.primary_user.full_name,
          class: newUserData.primary_user.class_type,
          email: newUserData.primary_user.email,
          grade: newUserData.primary_user.grade,
          id: newUserData.primary_user.id,
          studentId: newUserData.primary_user.student_id,
          stream: newUserData.primary_user.stream
        });
        setUserProperties({
          name: newUserData.primary_user.full_name,
          email: newUserData.primary_user.email,
          id: newUserData.primary_user.id,
          mobile: newUserData.primary_user.phone_number,
          whatsAppConsent: user.isWhatsappConsentGiven,
          onBoardingDate: null,
          isOnboarded: true,
          grade: newUserData.primary_user.grade,
          stream: newUserData.primary_user.stream,
          class: newUserData.primary_user.class_type
        });
        Sentry.setUser(null);
        Sentry.setUser({
          id: newUserData.primary_user.id,
          email: newUserData.primary_user.email,
          username: newUserData.primary_user.student_id,
          name: newUserData.primary_user.full_name
        });
        trackEvent({ eventName: eventNames.studentProfileSwitched });

        // Fetch subscription status of the new primary user
        if (updatedPrimaryUser?.access_token)
          await userSubscription.fetchSubscriptionStatus(
            updatedPrimaryUser.access_token
          );
      }

      onClose && onClose();
      if (isLoginPage) {
        redirectAfterLogin();
      }
      setIsSwitchingProfile(false);
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        heading: "Maaf ya, ada yang salah. Coba lagi, yuk.",
        variant: "error"
      });
    }
  };

  return (
    <>
      <ProfileSelectionContainer isUIBlocked={isSwitchingProfile}>
        {userData.student_users.map((student, index) => (
          <ProfileContainer
            data-testid={`student-profile-${index}`}
            isSelected={selectedProfileID === student.id}
            onClick={() => setSelectedProfileID(student.id)}
            key={student.id}
          >
            <ProfileDesktopAvatar>
              <Image
                src={student.avatar_url || userProfileImage}
                alt={student.full_name}
                objectFit="cover"
                layout="fixed"
                height="72"
                width="72"
              />
            </ProfileDesktopAvatar>
            <ProfileMobileAvatar>
              <Image
                src={userProfileImage}
                alt={student.full_name}
                objectFit="cover"
                layout="fixed"
                height="56"
                width="56"
              />
            </ProfileMobileAvatar>
            <ProfileDetailsContainer>
              <ProfileDetailsItem>{student.full_name}</ProfileDetailsItem>
              <ProfileDetailsItem>
                {student.class_type} {student.grade} {student.stream}
              </ProfileDetailsItem>
            </ProfileDetailsContainer>
          </ProfileContainer>
        ))}
      </ProfileSelectionContainer>
      <SelectProfileButtonContainer>
        <SelectProfileButton
          data-testid="select-student-profile"
          onClick={updatePrimaryUser}
          variant="primary"
          disabled={selectedProfileID === null || isSwitchingProfile}
          isLoading={isSwitchingProfile}
          loadingText="Pilih profil"
        >
          Pilih profil
        </SelectProfileButton>
      </SelectProfileButtonContainer>
    </>
  );
};

export { ProfileSelection };
