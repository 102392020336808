import styled, { keyframes } from "styled-components";
import { breakpoints } from "styles";
import { Direction, IIcon } from "./icons.types";

const changeDirection = (direction: Direction) => {
  switch (direction) {
    case "left":
      return "";
    case "right":
      return "rotate(180deg)";
    case "up":
      return "rotate(90deg)";
    case "down":
      return "rotate(-90deg)";
  }
};

export const StyledChevron = styled("svg")<IIcon>`
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  transform: ${({ direction }) => direction && changeDirection(direction)};
`;

export const StyledArrow = styled("svg")<IIcon>`
  transition: transform 0.5s;
  transform: ${({ direction }) => direction && changeDirection(direction)};
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{
  width: number | string;
  height: number | string;
}>`
  animation: ${rotate360} 2s linear infinite;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const BackBtnStyles = styled.span`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  @media ${breakpoints.laptop} {
    color: ${({ theme }) => theme.colors.black};
  }
`;
