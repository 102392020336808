import { callMeBackGrades } from "components/call-me-back/cmb-form/cmb-form.data";
import { Input } from "components/form-component/input/input";
import { MobileInput } from "components/form-component/mobile-input/mobile-input";
import { Select } from "components/form-component/select/select";
import React from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { bannerIntentEventNames } from "services/mixpanel/tanya-events";
import { ContactMeUserDetailsFormProps } from "../use-contact-me.types";
import {
  ButtonContainer,
  FormContainer,
  StyledForm,
  SubmitButton
} from "./contact-me-form.styles";

export const ContactMeUserDetailsForm: React.FC<
  ContactMeUserDetailsFormProps
> = ({
  control,
  isLoading,
  handleSubmit,
  onSubmit,
  getValues,
  metaData,
  leadSource,
  buttonCTAText
}) => {
  const trackMobile = () => {
    trackEvent({
      eventName: bannerIntentEventNames.leadPhoneNumberEntered,
      payload: {
        leadMobile: getValues().phoneNumber,
        leadSource: leadSource,
        ...metaData
      }
    });
  };

  const trackName = () => {
    const values = getValues();
    trackEvent({
      eventName: bannerIntentEventNames.leadNameEntered,
      payload: {
        leadMobile: values.phoneNumber,
        leadName: values.name,
        leadSource: leadSource,
        ...metaData
      }
    });
  };

  const trackGrade = () => {
    const values = getValues();
    trackEvent({
      eventName: bannerIntentEventNames.leadGradeEntered,
      payload: {
        leadMobile: values.phoneNumber,
        leadGrade: values.grade,
        leadName: values.name,
        leadSource: leadSource,
        ...metaData
      }
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id="contact-me-form">
      <FormContainer>
        <MobileInput
          name="phoneNumber"
          label="Nomor WhatsApp Orang Tua"
          placeholder="Contoh: 081234567890"
          control={control}
          onBlur={trackMobile}
        />
        <Input
          testId="isi-nama-nama-orang-tua-textfield"
          placeholder="Isi namamu di sini"
          label="Nama Orang Tua"
          control={control}
          name="name"
          onBlur={trackName}
        />
        <Select
          options={callMeBackGrades.map((grade) => {
            return {
              name: `Kelas - ${grade}`,
              value: grade
            };
          })}
          placeholder="Pilih kelas"
          name="grade"
          label="Pilih Kelas untuk Bimbel Anak"
          // @ts-expect-error
          control={control}
          onBlur={trackGrade}
          testId="pilih-bimbel-untuk-kelas-dropdown"
        />
        <ButtonContainer>
          <SubmitButton
            data-testid="oke-minta-info-bimbel-button"
            type="submit"
            variant="primary"
            isLoading={isLoading}
          >
            {buttonCTAText ? buttonCTAText : "Oke, minta info Bimbel"}
          </SubmitButton>
        </ButtonContainer>
      </FormContainer>
    </StyledForm>
  );
};
