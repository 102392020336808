import { AuthContextType } from "./auth-context.types";
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage
} from "services/local-storage";
import { constants } from "constants/constants";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { isObjectEmpty } from "utils/utils";
import { resetSuperProperties } from "services/mixpanel/mixpanel";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/react";

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const useAuth = (): AuthContextType => useContext(AuthContext);

export function AuthProvider({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, _setCookie, removeCookie] = useCookies();

  const [isWhatsappConsentGiven, setIsWhatsappConsentGiven] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [qnaToken, setQnaToken] = useState<string | null>(null);
  const [userData, setUserData] = useState(
    getLocalStorage(
      constants.userContext.sessionKey
    ) as AuthContextType["user"]["userData"]
  );
  const router = useRouter();

  const signOutUser = () => {
    clearLocalStorage();
    resetSuperProperties();
    Sentry.setUser(null);
    clearAuthCookies();
    signOut({ callbackUrl: process.env.NEXT_PUBLIC_SITE_URL });
  };

  const clearAuthCookies = () => {
    removeCookie(constants.userContext.cookieKey, { path: "/" });
    removeCookie(constants.userContext.cookieKeyParent, { path: "/" });
    removeCookie(constants.userContext.cookieKeyStudent, { path: "/" });
    removeCookie(constants.userContext.cookiePrimaryUserSubscriptionType, {
      path: "/"
    });
    removeCookie(constants.userContext.cookieTanyaSubscriptionStatus, {
      path: "/"
    });
  };

  const isTokenExpired = (token: JwtPayload) => {
    if (token.exp && Date.now() > token.exp * 1000) {
      signOutUser();
    }
  };

  useEffect(() => {
    if (userData && !isObjectEmpty(userData) && !userData.register_token) {
      setIsUserLoggedIn(true);
    }
    setLocalStorage(constants.userContext.sessionKey, userData);
  }, [userData]);

  // checks if the token is expired
  useEffect(() => {
    if (userData.register_token) {
      isTokenExpired(jwt_decode(userData.register_token));
    } else if (userData.primary_user && !isObjectEmpty(userData.primary_user)) {
      isTokenExpired(jwt_decode(userData.primary_user.access_token));
    } else if (
      isObjectEmpty(userData.primary_user) &&
      !isObjectEmpty(userData.parent_user)
    ) {
      isTokenExpired(jwt_decode(userData.parent_user.access_token));
    }
  }, [router]);

  return (
    <AuthContext.Provider
      value={{
        user: {
          isUserLoggedIn,
          isWhatsappConsentGiven,
          setIsWhatsappConsentGiven,
          userData,
          setUserData
        },
        signOutUser,
        qna: {
          token: qnaToken,
          setToken: setQnaToken
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
