export enum ModalSize {
  Normal = "normal",
  Wide = "wide",
  Narrow = "narrow",
  ExtraWide = "extraWide",
  Tiny = "tiny",
  Full = "full"
}

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  heading?: string;
  size?: ModalSize;
  testId?: string;
  displayProperties?: {
    backgroundColor?: string;
  };
  closeIconProperties?: {
    color?: string;
    isHidden?: boolean;
  };
  closeOnClickOutside?: boolean;
};
