import React from "react";
import { FormProps } from "./form.types";

const Form = ({ children, onSubmit, ...props }: FormProps): JSX.Element => {
  return (
    <form onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};

export { Form };
