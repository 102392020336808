import {
  Control,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit
} from "react-hook-form";
import { UserType } from "types/common/common.types";

export type ContactMeFormMetaData = Record<
  string,
  string[] | string | number | boolean | null | undefined
>;
export interface IContactMeProps {
  onClose: () => void;
  metaData?: ContactMeFormMetaData;
  leadSource: ContactMeLeadSource | null;
  buttonCTAText?: string;
}

export interface ContactMeFormValues {
  phoneNumber: string;
  name: string;
  grade: string;
  callbackRequestedBy: UserType;
}

export interface IUseContactMeProps {
  metaData: ContactMeFormMetaData;
  onClose: () => void;
  leadSource: ContactMeLeadSource | null;
}

export interface IUseContactMe {
  control: Control<ContactMeFormValues>;
  handleSubmit: UseFormHandleSubmit<ContactMeFormValues>;
  isValid: boolean;
  onUserDetailsFormSubmit: SubmitHandler<ContactMeFormValues>;
  onJoiningReasonFormSubmit: (data: { reason: string }) => Promise<void>;
  formStep: ContactMeFormSteps;
  isLoading: boolean;
  getValues: UseFormGetValues<ContactMeFormValues>;
  handleClose: () => void;
  handleSuccessClose: () => void;
}

export type ContactMeUserDetailsFormProps = Pick<
  IUseContactMe,
  "control" | "handleSubmit" | "isValid" | "isLoading" | "getValues"
> & {
  onSubmit: IUseContactMe["onUserDetailsFormSubmit"];
  onSkip: () => void;
  metaData: ContactMeFormMetaData;
  leadSource: ContactMeLeadSource | null;
  buttonCTAText?: string;
};

export enum ContactMeLeadSource {
  homePageBanner = "Home Page Banner",
  tanyaPageBanner = "Tanya Page Banner",
  paketPageBanner = "Paket Page Banner",
  paketWebCheckout = "Paket Web Checkout",
  paketAutoOpen = "Paket Auto Open",
  bimbelPageBanner = "Bimbel Page Banner",
  inHouseAds = "in-house ads"
}

export enum ContactMeFormSteps {
  CAPTURE_USER_DETAILS = "CAPTURE_USER_DETAILS",
  CAPTURE_REASON_FOR_JOINING = "CAPTURE_REASON_FOR_JOINING",
  DETAILS_ENTERED_SUCCESSFULLY = "DETAILS_ENTERED_SUCCESSFULLY"
}

export type ContactMeJoiningReasonFormProps = {
  onSubmit: IUseContactMe["onJoiningReasonFormSubmit"];
  onSkip: () => void;
  metaData: ContactMeFormMetaData;
  leadSource: ContactMeLeadSource | null;
};
