import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 16px;
`;

export const ModalBody = styled.div`
  @media ${breakpoints.mobile} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
  }
`;

export const ModalHeading = styled.h4`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 36px;
  @media ${breakpoints.mobile} {
    font-size: ${rem(22)};
    line-height: 28px;
  }
`;

export const ModalSubHeading = styled.h6`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  line-height: 26px;
  font-weight: 700;
  margin: 8px 0 0;

  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["sm"]};
    line-height: 20px;
  }
`;

export const JoiningReasonModalSubHeading = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 0;

  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["sm"]};
    line-height: 20px;
  }
`;

export const JoiningReasonModalHeadingContainer = styled.div`
  @media ${breakpoints.mobile} {
    text-align: center;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  img {
    margin-top: 5px;
  }
`;
