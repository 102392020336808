import { BannerLoader, BreadCrumbLoader } from "components/loader/components";
import { SearchBarLoader } from "components/search-bar/search-bar-loader/search-bar-loader";
import { VideoCardLoader } from "components/video-card/video-card-loader/video-card-loader";
import { TanyaAllVideoGrid } from "styles/pages/tanya-topic.styles";
import {
  VideoDetailHeaderCard,
  VideoDetailsBannerContainer,
  VideoDetailsContainer,
  VideoRecommendationsContainer,
  VideoRecommendationsListContainer,
  VideoRecommendationsTitle
} from "styles/pages/tanya-video-detail.styles";
import {
  VideoDataContainer,
  VideoPlayerLoader
} from "./video-page-loader.styles";

const videoCardCount = 4;
const breadCrumbCount = 4;

const VideoPageLoader: React.FC = () => (
  <div aria-label={"video-page-loader"}>
    <SearchBarLoader />
    <VideoPlayerLoader />
    <VideoDetailsContainer>
      <VideoDetailHeaderCard>
        <BreadCrumbLoader count={breadCrumbCount} />
        <VideoDataContainer>
          <VideoCardLoader id="video-data-loader" showVideoLoader={false} />
        </VideoDataContainer>
        <VideoRecommendationsContainer>
          <VideoRecommendationsListContainer>
            <VideoRecommendationsTitle>
              Rekomendasi video solusi lainnya
            </VideoRecommendationsTitle>
            <TanyaAllVideoGrid>
              <VideoCardLoader
                id="recomendations-page-loader"
                count={videoCardCount}
              />
            </TanyaAllVideoGrid>
          </VideoRecommendationsListContainer>
        </VideoRecommendationsContainer>
      </VideoDetailHeaderCard>
      <VideoDetailsBannerContainer>
        <BannerLoader />
      </VideoDetailsBannerContainer>
    </VideoDetailsContainer>
  </div>
);

export { VideoPageLoader };
