import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { breakpoints } from "styles";

export const BreadCrumbLoaderContainer = styled.div`
  display: flex;

  @media ${breakpoints.laptop} {
    width: 100%;
  }
`;

export const BreadCrumbLoaderItem = styled(Skeleton)`
  border-radius: 6px;
  height: 24px;
  width: 64px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;
