import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { sizes } from "styles";

export const SearchBarContainer = styled.div`
  text-align: center;
  padding: 0 16px;
`;

export const SearchBar = styled(Skeleton)`
  width: 100%;
  max-width: ${sizes.containerWidth};
  height: 52px;
`;
