import React from "react";
import type { IIcon } from "./icons.types";

const CrossIcon: React.FC<IIcon> = ({
  width = 15,
  height = 16,
  color = "currentColor"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7682 7.19944L14.6229 3.32303C15.1257 2.81742 15.1257 1.97472 14.6229 1.4691L14.0363 0.879213C13.5335 0.373596 12.6955 0.373596 12.1927 0.879213L8.33799 4.75562C7.91899 5.17697 7.1648 5.17697 6.74581 4.75562L2.80726 0.879213C2.30447 0.373596 1.46648 0.373596 0.963687 0.879213L0.377095 1.4691C-0.125698 1.97472 -0.125698 2.81742 0.377095 3.32303L4.23184 7.19944C4.65084 7.62079 4.65084 8.37921 4.23184 8.80056L0.377095 12.677C-0.125698 13.1826 -0.125698 14.0253 0.377095 14.5309L0.963687 15.1208C1.46648 15.6264 2.30447 15.6264 2.80726 15.1208L6.66201 11.2444C7.081 10.823 7.83519 10.823 8.25419 11.2444L12.1089 15.1208C12.6117 15.6264 13.4497 15.6264 13.9525 15.1208L14.5391 14.5309C15.0419 14.0253 15.0419 13.1826 14.5391 12.677L10.7682 8.88483C10.3492 8.37921 10.3492 7.70506 10.7682 7.19944Z"
      fill={color}
    />
  </svg>
);

export { CrossIcon };
