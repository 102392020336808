import { WebPlatform } from "utils/mixpanel/mixpanel-utils.types";
import { useMediaQuery } from "./use-media-query";

const useGetCurrentPlatform = (): WebPlatform => {
  const isDesktopPlatform = useMediaQuery("laptop");
  const isTabletPlatform = useMediaQuery("tablet");
  return isDesktopPlatform
    ? WebPlatform.Desktop
    : isTabletPlatform
    ? WebPlatform.Tablet
    : WebPlatform.Mobile;
};

export { useGetCurrentPlatform };
