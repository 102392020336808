import { useState } from "react";
import { IApiError } from "services/api/api.types";
import { SubscriptionStatus } from "services/api/sessions/sessionService.types";
import { getSubscriptionStatus } from "services/api/sessions/sessionsService";
import { useAuth } from "stores/auth/auth-context";
import { IUseSubscriptionStatus } from "./subscription-status.types";
import * as Sentry from "@sentry/nextjs";
import { parseAPIErrorResponse } from "utils/utils";
import { useCookies } from "react-cookie";
import { constants } from "constants/constants";
import { TanyaSubscriptionInfo } from "services/api/sessions/sessionService.types";

const useSubscriptionStatus = (): IUseSubscriptionStatus => {
  const [, setCookie, removeCookie] = useCookies();
  const [isFetching, setIsFetching] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus | null>(null);

  const { signOutUser } = useAuth();

  const fetchSubscriptionStatus = async (token: string) => {
    try {
      setIsFetching(true);
      const {
        data: { subscriptionStatus }
      } = await getSubscriptionStatus(token);

      // Remove existing subscription related cookies
      removeCookie(constants.userContext.cookiePrimaryUserSubscriptionType, {
        path: "/"
      });
      removeCookie(constants.userContext.cookieTanyaSubscriptionStatus, {
        path: "/"
      });

      // Set user subscription
      setSubscriptionStatus(subscriptionStatus);

      setCookie(
        constants.userContext.cookiePrimaryUserSubscriptionType,
        subscriptionStatus.subscriptionType,
        { path: "/" }
      );
      const activeTanyaSubscriptionInfo: TanyaSubscriptionInfo | null = null;
      setIsFetching(false);
      return { subscriptionStatus, activeTanyaSubscriptionInfo };
    } catch (err) {
      Sentry.captureException(err);
      removeCookie(constants.userContext.cookiePrimaryUserSubscriptionType, {
        path: "/"
      });
      removeCookie(constants.userContext.cookieTanyaSubscriptionStatus, {
        path: "/"
      });
      const error = parseAPIErrorResponse(err) as IApiError;
      const status = error?.errors?.status;
      if (status === 401) {
        signOutUser();
      }
      Sentry.captureException(err);
    }
    setIsFetching(false);
    return null;
  };

  return {
    subscriptionStatus,
    setSubscriptionStatus,
    fetchSubscriptionStatus,
    isFetching
  };
};

export { useSubscriptionStatus };
