import styled from "styled-components";
import Image from "next/legacy/image";
import { breakpoints, sizes } from "styles";

export const StyledUserProfile = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: ${({ theme }) => theme.fonts.primary};
  border: 1px solid ${({ theme }) => theme.colors.neutral["100"]};
  border-radius: 8px;
  position: relative;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  margin-left: auto;
  width: auto;
  @media ${breakpoints.laptop} {
    width: ${sizes.userProfileWidth.laptop};
    display: flex;
  }
`;

export const MobileStyledUserProfile = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-family: ${({ theme }) => theme.fonts.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: auto;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brand[500]};
  }
`;

export const UserAvatar = styled(Image)`
  border-radius: 50%;
  position: relative;
`;

export const UserPremiumBadge = styled.span`
  position: absolute;
  bottom: 4px;
  left: 38px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`;

export const UserDetails = styled.div`
  flex: 1;
  padding: 0 16px 0 8px;
  display: flex;
  flex-direction: column;
`;

export const UserStyledChevron = styled.div`
  margin-left: auto;
`;

export const UserName = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserSubDetails = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const UserDropdownMenu = styled.ul`
  position: absolute;
  top: 50px;
  right: -10px;
  width: ${sizes.userProfileDropdownMenuWidth};
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  list-style-type: none;
`;

export const UserDropdownMenuItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-weight: bold;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brand[500]};
  }

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral["100"]};
  }
`;

export const ChangeProfile = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding-top: 12px;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brand[500]};
  }
`;
