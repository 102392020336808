import styled from "styled-components";
import { ButtonType, ButtonSize } from "./button.types";
import { breakpoints, theme } from "styles";

const backgroundColorMap = {
  primary: theme.colors.brand[500],
  secondary: theme.colors.brand[100],
  ghost: "transparent",
  link: "transparent",
  light: theme.colors.white
};

const colorMap = {
  primary: theme.colors.white,
  secondary: theme.colors.brand[500],
  ghost: theme.colors.brand[500],
  link: theme.colors.brand[500],
  light: theme.colors.brand[500]
};

const hoverBackgroundColorMap = {
  primary: theme.colors.brand[600],
  secondary: theme.colors.brand[200],
  ghost: theme.colors.brand[100],
  link: "transparent",
  light: theme.colors.white
};

export const StyledButton = styled.button<{
  $variant: ButtonType;
  $isLoading: boolean;
  $isFullWidth?: boolean;
  $size?: ButtonSize;
}>`
  padding: ${({ $variant, $size }) =>
    $variant === "link"
      ? 0
      : $size === ButtonSize.sm
        ? `0px 8px`
        : `12px 24px`};
  border-radius: 6px;
  height: ${({ $size }) => ($size === ButtonSize.sm ? `18px` : `46px`)};
  cursor: pointer;
  font-weight: bold;
  line-height: ${({ $size }) => ($size === ButtonSize.sm ? `16px` : `22px`)};
  font-size: ${({ theme, $size }) =>
    $size === ButtonSize.sm ? theme.fontSizes["xs"] : theme.fontSizes["md"]};
  outline: none;
  border: 0;
  width: ${({ $isFullWidth }) => ($isFullWidth ? `100%` : "auto")};
  min-width: fit-content;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  background-color: ${({ $variant }) => backgroundColorMap[$variant]};
  color: ${({ $variant }) => colorMap[$variant]};

  &:hover {
    background-color: ${({ $variant }) => hoverBackgroundColorMap[$variant]};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme, $isLoading }) =>
      $isLoading ? theme.colors.brand[300] : theme.colors.neutral[400]};
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${breakpoints.tablet} {
    padding: ${({ $variant, $size }) =>
      $variant === "link"
        ? 0
        : $size === ButtonSize.sm
          ? `4px 8px`
          : `12px 24px`};
    height: ${({ $size }) =>
      $size === ButtonSize.sm
        ? `24px`
        : $size === ButtonSize.lg
          ? `62px`
          : `46px`};
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
