import React from "react";
import {
  VideoLoader,
  VideoCardLoaderDetailsContainer,
  VideoCardLoaderShareButton,
  VideoCardLoaderStatsContainer,
  VideoCardLoaderTagContainer,
  VideoCardLoaderTagItem,
  VideoLoaderContainer,
  VideoTitleLoader
} from "./video-card-loader.styles";

type VideoCardLoaderProps = {
  showVideoLoader?: boolean;
  id: string;
  count?: number;
};

const VideoCardLoader: React.FC<VideoCardLoaderProps> = ({
  showVideoLoader = true,
  id,
  count = 1
}) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <VideoLoaderContainer key={`${id}-${index}`}>
          {showVideoLoader && <VideoLoader />}
          <VideoTitleLoader />
          <VideoCardLoaderDetailsContainer>
            <VideoCardLoaderTagContainer>
              <VideoCardLoaderTagItem />
              <VideoCardLoaderTagItem />
              <VideoCardLoaderTagItem />
              <VideoCardLoaderTagItem />
            </VideoCardLoaderTagContainer>
            <VideoCardLoaderStatsContainer>
              <VideoCardLoaderShareButton />
            </VideoCardLoaderStatsContainer>
          </VideoCardLoaderDetailsContainer>
        </VideoLoaderContainer>
      ))}
    </>
  );
};

export { VideoCardLoader };
