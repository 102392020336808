import { defaultCurriculum } from "constants/constants";
import { generateUUID } from "utils/utils";
import wretch from "wretch";
import { IPrimaryUser } from "../api.types";
import {
  IActivateTrialForStudentResponse,
  ICreateSupportIssueV2Payload,
  IFocusExamResponse,
  IFreeSessionsAPIResponse,
  IGetContentForPausedUserResponse,
  IGetPastSessionsForUserResponse,
  IGetSessionsForUserResponse,
  IGetStudentSessionsBySlotResponse,
  IGetZoomURLResponse,
  ISessionAPIData,
  ISessionModalConfigurationResponse,
  ISubscriptionStatusResponse,
  ISupportIssueAPIResponse,
  IUserPurchasesResponse
} from "./sessionService.types";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_API;
const PACKAGES_BASE_URL = process.env.NEXT_PUBLIC_PACKAGES_API_URL;

export const getSessionsForUser = (
  userToken: string,
  focusExamsIds?: Array<string>
): Promise<IGetSessionsForUserResponse> => {
  const URL = focusExamsIds
    ? `${BASE_URL}/sessions/v1/students/sessions?focusExamIds=${focusExamsIds.join(
        ","
      )}`
    : `${BASE_URL}/sessions/v1/students/sessions`;
  return wretch(URL)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getFreeSessionsForUser = (
  primaryUser: IPrimaryUser
): Promise<IFreeSessionsAPIResponse> => {
  return wretch(
    `${BASE_URL}/sessions/v1/sessions/free?curriculum=${
      primaryUser.curriculum || defaultCurriculum
    }&class=${primaryUser.class_type}&grade=${primaryUser.grade}&stream=${
      primaryUser.stream ? primaryUser.stream : ""
    }`
  )
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${primaryUser.access_token}`)
    .get()
    .json();
};

export const getSubscriptionStatus = (
  userToken: string
): Promise<ISubscriptionStatusResponse> => {
  return wretch(`${BASE_URL}/sessions/v2/students/subscription-status`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getPurchasesOfUser = (
  userToken: string
): Promise<IUserPurchasesResponse[]> => {
  return wretch(`${PACKAGES_BASE_URL}/api/v1/purchases`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getFocusExamsOfUser = (
  userToken: string
): Promise<IFocusExamResponse> => {
  return wretch(`${BASE_URL}/sessions/v1/students/my-focus-exam`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getJoinClassLink = (
  userToken: string,
  classID: string,
  userID: string
): Promise<IGetZoomURLResponse> => {
  return wretch(
    `${process.env.NEXT_PUBLIC_LIVE_CLASSES_BASE_URL}/api/v1/live_classes/${classID}/get_join_url/${userID}`
  )
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const activateTrialForStudent = (
  userToken: string
): Promise<IActivateTrialForStudentResponse> => {
  return wretch(`${BASE_URL}/sessions/v1/students/activate-trial`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .put()
    .json();
};

export const getPastSessionsForUser = (
  userToken: string,
  focusExamIds?: Array<string> | null,
  limit = 10,
  offset = 0
): Promise<IGetPastSessionsForUserResponse> => {
  const URL = focusExamIds
    ? `${BASE_URL}/sessions/v1/students/sessions/past?limit=${limit}&offset=${offset}&focusExamIds=${focusExamIds}`
    : `${BASE_URL}/sessions/v1/students/sessions/past?limit=${limit}&offset=${offset}`;
  return wretch(URL)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getUIContentsForPausedUser = (
  userToken: string
): Promise<IGetContentForPausedUserResponse> => {
  return wretch(
    `${process.env.NEXT_PUBLIC_LIVE_CLASSES_BASE_URL}/api/v1/contents/package_subscriptions/paused_subscription_popup`
  )
    .headers({ "X-IQ-Locale": "id", "x-iq-request-id": generateUUID() })
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const createSupportIssue = (
  userToken: string,
  mobileNumber: string,
  source: string,
  action: string
): Promise<ISupportIssueAPIResponse> => {
  const payload = {
    phone_number: mobileNumber,
    source: source,
    action: action
  };
  return wretch(
    `${process.env.NEXT_PUBLIC_LIVE_CLASSES_BASE_URL}/api/v1/support_issues`
  )
    .headers({ "X-IQ-Locale": "id", "x-iq-request-id": generateUUID() })
    .auth(`Bearer ${userToken}`)
    .post(payload)
    .json();
};

export const createSupportIssueV2 = (
  payload: ICreateSupportIssueV2Payload
): Promise<void> => {
  return wretch(
    `${process.env.NEXT_PUBLIC_LIVE_CLASSES_BASE_URL}/api/v2/support_issues`
  )
    .headers({ "X-IQ-Locale": "id", "x-iq-request-id": generateUUID() })
    .post(payload)
    .json();
};

export const getStudentSessionsBySlot = (
  userToken: string,
  slotId: string,
  startDate: string,
  endDate: string
): Promise<IGetStudentSessionsBySlotResponse> => {
  return wretch(
    `${BASE_URL}/sessions/v1/students/sessions/slots/${slotId}?startDate=${startDate}&endDate=${endDate}`
  )
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getSessionBySessionId = (
  sessionId: string,
  userToken: string
): Promise<ISessionAPIData> => {
  return wretch(`${BASE_URL}/sessions/v1/sessions/${sessionId}`)
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};

export const getModalConfiguration = (
  userToken: string
): Promise<ISessionModalConfigurationResponse> => {
  return wretch(`${BASE_URL}/api/v1/modals/configuration`)
    .auth(`Bearer ${userToken}`)
    .get()
    .json();
};
