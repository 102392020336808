import React from "react";
import { StyledChevron } from "./icons.styles";
import type { IIcon } from "./icons.types";

const Chevron: React.FC<IIcon> = ({
  width = 20,
  height = 20,
  color = "currentColor",
  direction = "left"
}) => {
  const numericWidth = typeof width === "string" ? parseFloat(width) : width;
  const numericHeight =
    typeof height === "string" ? parseFloat(height) : height;
  const scale = Math.min(numericWidth / 24, numericHeight / 24);

  return (
    <StyledChevron
      width={width}
      height={height}
      viewBox={`0 0 ${numericWidth} ${numericHeight}`}
      direction={direction}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.51592 15.2985L12.3901 21.4885C12.7009 21.8152 13.1216 21.9987 13.5602 21.9987C13.9988 21.9987 14.4195 21.8152 14.7303 21.4885L15.5167 20.6585C15.8262 20.3305 16 19.8865 16 19.4235C16 18.9606 15.8262 18.5165 15.5167 18.1885L10.6563 13.0585C10.5233 12.9199 10.4178 12.7549 10.3458 12.5729C10.2738 12.391 10.2367 12.1957 10.2367 11.9985C10.2367 11.8013 10.2738 11.6061 10.3458 11.4241C10.4178 11.2422 10.5233 11.0771 10.6563 10.9385L15.5072 5.80852C15.8167 5.48053 15.9905 5.03646 15.9905 4.57352C15.9905 4.11059 15.8167 3.66651 15.5072 3.33852L14.7208 2.51852C14.5682 2.35434 14.3862 2.22392 14.1852 2.13489C13.9843 2.04586 13.7686 2 13.5507 2C13.3328 2 13.1171 2.04586 12.9162 2.13489C12.7153 2.22392 12.5332 2.35434 12.3806 2.51852L6.51592 8.69852C5.65464 9.57307 5.11713 10.7432 5 11.9985C5.13642 13.248 5.67092 14.4116 6.51592 15.2985Z"
        fill={color}
        transform={`scale(${scale})`}
      />
    </StyledChevron>
  );
};

export { Chevron };
