import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { breakpoints } from "styles";

export const VideoLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const VideoCardLoaderDetailsContainer = styled.div`
  @media ${breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-template-areas:
      "title title"
      "topics share";
  }
`;

export const VideoLoader = styled(Skeleton)`
  width: 100%;
  position: relative;
  padding-top: 56.65%;
  margin: 0;
  border-radius: 6px;
  overflow: hidden;

  &:after {
    top: 0;
  }
`;

export const VideoTitleLoader = styled(Skeleton)`
  height: 42px;
  width: 100%;

  @media ${breakpoints.tablet} {
    height: 36px;
    @media ${breakpoints.tablet} {
      grid-area: title;
    }
  }
`;

export const VideoCardLoaderTagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  @media ${breakpoints.tablet} {
    grid-area: topics;
  }
`;

export const VideoCardLoaderTagItem = styled(Skeleton)`
  border-radius: 20px;
  height: 16px;
  width: 65px;
  padding: 4px 8px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const VideoCardLoaderStatsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
  @media ${breakpoints.tablet} {
    grid-area: share;
  }
`;

export const VideoCardLoaderShareButton = styled(Skeleton)`
  height: 20px;
  width: 65px;
  padding: 4px 8px;
`;
