import { rem } from "polished";

export const rootFontSize = "16px";

export const fonts = {
  primary: "'Poppins', system-ui",
  secondary: "'Overpass', system-ui",
  systemUI: "system-ui"
};

//Fix font-sizes accordingly to base 10px rem sizing
export const fontSizes = {
  xxs: `${rem(10)}`,
  xs: `${rem(12)}`,
  sm: `${rem(14)}`,
  md: `${rem(16)}`,
  lg: `${rem(18)}`,
  xl: `${rem(20)}`,
  sxl: `${rem(22)}`,
  "2xl": `${rem(24)}`,
  "2hxl": `${rem(28)}`,
  "3xl": `${rem(30)}`,
  "4xl": `${rem(36)}`,
  "5xl": `${rem(48)}`,
  "6xl": `${rem(60)}`,
  "7xl": `${rem(72)}`,
  custom46: `${rem(46)}`
};

export type IFonts = {
  primary: string;
  secondary: string;
  systemUI: string;
};

export type IFontSizes = {
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  sxl: string;
  "2xl": string;
  "2hxl": string;
  "3xl": string;
  "4xl": string;
  "5xl": string;
  "6xl": string;
  "7xl": string;
  custom46: string;
};
